import { gql } from '@apollo/client';

const LIST_COMPANY_SETTING = gql`
  query companySettings($keyword: String, $pageNumber: Int, $pageSize: Int, $companyId: Int!) {
    companySettings(data: { keyword: $keyword, pageNumber: $pageNumber, pageSize: $pageSize, companyId: $companyId }) {
      id
      key
      value
      companyId
    }
    countCompanySettings(data: { keyword: $keyword, companyId: $companyId })
  }
`;

const CREATE_UPDATE_COMPANY_SETTING = gql`
  mutation creatAndUpdateSetting($id: Int, $key: CompanySettingKeyEnum!, $value: Float!, $companyId: Int!) {
    creatAndUpdateSetting(data: { id: $id, key: $key, value: $value, companyId: $companyId }) {
      id
      message
    }
  }
`;

export { LIST_COMPANY_SETTING, CREATE_UPDATE_COMPANY_SETTING };
