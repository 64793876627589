/*
 *
 * Brand reducer
 *
 */

// import { fromJS } from "immutable";
import { GET_ON_OFF_ACTION } from './constants';

export const initialState = {
  onStatus: false,
};

function countReducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_ON_OFF_ACTION:
      return { ...state, onStatus: action.searchData.status };
    default:
      return state;
  }
}

export default countReducer;
