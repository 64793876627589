import { compose } from '@reduxjs/toolkit';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { injectReducer } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';
import { testOnOf } from './actions';
import countReducer from './reducer';
import { getStatus } from './selectors';

// const mapStateToProps = (state: any) => {
//   return {
//     onStatus: state.counter.onStatus,
//   };
// };

const mapStateToProps = createStructuredSelector({
  onStatus: getStatus(),
});
const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleOn: (value: boolean) => dispatch(testOnOf({ status: value })),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends PropsFromRedux {}

class MyComponent extends React.Component<Props, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      onStatus: false,
    };
  }
  render() {
    return (
      <div>
        <button onClick={() => this.props.toggleOn(!this.props.onStatus)}>Toggle is {this.props.onStatus.toString()}</button>
      </div>
    );
  }
}
// const withConnect = connect(mapStateToProps, mapDispatchToProps);

const injectReduc = injectReducer({ key: 'counter', reducer: countReducer });
export default compose(injectReduc, connector)(MyComponent) as any;
