import { ApolloClient, InMemoryCache, HttpLink, from, ApolloLink, concat } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { SecurityHelper } from '@helpers/securityHelper';
import { Message } from '@models/base/message';
import * as momentTimeZone from 'moment-timezone';

const AUTH_BASE_URL = process.env.REACT_APP_GATE_WAY + '/auth/graphql';
const CORE_BASE_URL = process.env.REACT_APP_GATE_WAY + '/core/api/admin/graphql';

const httpLink = new HttpLink({
  uri: CORE_BASE_URL,
});
const authenLink = new HttpLink({
  uri: AUTH_BASE_URL,
});

const errorLink = onError(({ graphQLErrors, networkError }: any) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }: any) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    );
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const decryptData = new ApolloLink((operation, forward) => {
  return forward(operation).map((response: any) => {
    // if (response.data.list) {
    //   const data = response.data.list;
    //   if (data && data.hashData && data.isEncry) {
    //     response.data.list.encryData = SecurityHelper.decrypt(data.hashData, true, SecurityHelper.token);
    //     response.data.list.hashData = null;
    //   }
    // }
    return response;
  });
});

const getHeader = (paramsInput?: any) => {
  const headers: any = {};
  const timeZone = momentTimeZone.tz.guess();
  headers['timezone'] = timeZone;
  const authToken = SecurityHelper.getStoreAuthen();
  if (authToken && authToken.token) {
    headers['authorization'] = authToken.token || '';
    if (authToken.user && authToken.user.fullName) {
      headers['fullname'] = authToken.user?.fullName.toString().removeVietnamese() || '';
    }
  }
  if (paramsInput) {
    const ignores = paramsInput?.ignores || [];
    const listKeys = Object.keys(paramsInput).map(key => {
      return { name: key, value: paramsInput[key] };
    });
    let queryParamsString = '';
    listKeys.forEach(item => {
      if (item.name !== 'isGenerate' && item.name !== 'ignores') {
        const check = ignores.find((x: any) => x === item.name);
        if (item.value !== undefined && item.value !== null && !check) {
          if (queryParamsString !== '') {
            queryParamsString += '&';
          }
          queryParamsString += item.name + '=' + encodeURIComponent(item.value);
        }
      }
    });
    if (paramsInput.isGenerate) {
      const newUrl = window.location.pathname + '?' + queryParamsString;
      window.history.replaceState({}, '', newUrl); // window.location.replace(newUrl);
    }
  }
  return headers;
};

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const params = operation.variables;
  const headerss = getHeader(params);
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      ...headerss,
    },
  }));

  return forward(operation);
});

const link = from([errorLink, httpLink]);

const cache = new InMemoryCache();

const graphSqlClient = new ApolloClient({
  // link: from([errorLink, httpLink]),
  link: ApolloLink.split(
    operation => operation.getContext().clientName === 'authenLink',
    // the string "third-party" can be anything you want,
    // we will use it in a bit
    // <= apollo will send to this if clientName is "third-party"
    decryptData.concat(concat(authMiddleware, authenLink)),
    decryptData.concat(concat(authMiddleware, link)),
  ),
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  },
});

const getMessage = (respone: any): Message => {
  if (respone.data) {
    let [first] = Object.keys(respone.data);
    const message = (respone.data as any)[first];
    return message;
  }
  return new Message();
};
const getData = <T>(respone: any): T | undefined => {
  if (respone.data) {
    let [first] = Object.keys(respone.data);
    const message = (respone.data as any)[first];
    return message;
  }
  return undefined;
};
const getEncryptData = <T>(respone: any): T | null => {
  if (respone.data) {
    let [first] = Object.keys(respone.data);
    const data = (respone.data as any)[first];
    if (data && data.__typename === 'EncryptResponed' && data.hashData) {
      const result: T = SecurityHelper.decrytDataGraph(data);
      return result;
    }
    return null;
  }
  return null;
};

export { graphSqlClient, getMessage, getData, getEncryptData };
