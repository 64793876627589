import { BaseService } from '@helpers/https';
import { FileUpload, FileUploaded } from '@models/base';
import { Observable } from 'rxjs';

export class CDNService extends BaseService {
  private cdnUrl = process.env.REACT_APP_CDN_URL + '/cdn/system/';
  hostUrl = this.host + '/api/system/';
  private static _instance: CDNService;
  static get instance() {
    if (!this._instance) this._instance = new CDNService();
    return this._instance;
  }

  uploadCvPreview(data: any, mainFolder: 'company' | 'referral' | 'TNM') {
    const url = this.cdnUrl + `upload-cv-preview`;
    if (data) {
      data.append('type', mainFolder);
    }
    return this.postUrl(url, data);
    // return this.httpClient.postSync(url, data, { options: { responseType: 'blob' } });
  }

  upload(data: any, mainFolder: 'company' | 'referral' | 'TNM'): Observable<FileUpload> {
    const url = this.cdnUrl + `upload`;
    if (data && data instanceof FormData) {
      data.append('type', mainFolder);
    } else {
      data.type = mainFolder;
    }
    return this.postUrl(url, data);
  }
  uploadAvatar(data: any, mainFolder: 'company' | 'referral' | 'TNM'): Observable<FileUploaded> {
    const url = this.cdnUrl + `upload`;
    if (data && data instanceof FormData) {
      data.append('type', mainFolder);
      data.append('prefixFolder', 'avartar');
    } else {
      data.type = mainFolder;
    }
    return this.postUrl(url, data);
  }

  uploads(data: any, mainFolder: 'company' | 'referral' | 'TNM'): Observable<FileUploaded[]> {
    const url = this.cdnUrl + 'uploads';
    if (data && data instanceof FormData) {
      data.append('type', mainFolder);
    } else {
      data.type = mainFolder;
    }
    return this.postUrl(url, data);
  }
  uploadContract(data: any) {
    const url = this.cdnUrl + 'upload-contract';
    return this.postUrl(url, data);
  }

  uploadAssessmentForm(data: any) {
    const url = this.cdnUrl + 'upload-assessment-form';
    return this.postUrl(url, data);
  }

  downloadContract(namefile: string): any {
    const url = this.cdnUrl + +`file-dowload/${namefile}`;
    return this.getUrl(url, { options: { responseType: 'blob' } });
  }
  viewCv(fileName: string): any {
    if (fileName && typeof fileName === 'string') {
      const url = this.cdnUrl + `resume/${fileName}`;
      return this.getUrl(url, { options: { responseType: 'blob' } });
    }
  }
}
