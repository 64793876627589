import UploadImageSingle from '@components/Upload-Single-Image';
import { Loading } from '@helpers/loading';
import { Toastr } from '@helpers/toastr';
import { ApiResponse, FileUpload } from '@models/base';
import { UserModel } from '@models/users/user';
import { CDNService } from '@services/cdn-server.service';
import { UserService } from '@services/user.service';
import { Col, Form, Input, Row } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';

interface ProfileProps {}

const Profile: FunctionComponent<ProfileProps> = props => {
  const cdnService: CDNService = CDNService.instance;
  const userService: UserService = UserService.instance;
  let avatarFile: FileUpload = {};
  const [isUpdatePassword, setUpdatePassword] = useState(false);
  const [user, setUser] = useState<UserModel>({});
  const [form] = Form.useForm<UserModel>();

  useEffect(() => {
    getUserById();
  }, []);

  async function getUserById() {
    Loading.show();
    const user = await userService.getProfile<UserModel>().execute();
    if (user) {
      setUser(user);
      form.setFieldsValue({
        email: user.email,
        fullName: user.fullName,
        phoneNumber: user.phoneNumber,
        avatar: user.avatar,
      });
    }
    Loading.hide();
  }

  async function updateInfo(values: UserModel) {
    Loading.show();
    if (avatarFile && avatarFile.file) {
      avatarFile.old = user.avatar;
      const file = await cdnService.uploadAvatar(avatarFile, 'company').execute();
      user.avatar = file.url;
    }
    const userInput = { ...user, ...values };
    delete userInput.email;
    userService.editProfile(userInput).subscribe({
      next: (resp: ApiResponse) => {
        Toastr.message(resp.id, resp.message);
        if (resp && resp.id > 0) {
          //   TODO to session
          // updateAvatar(resp.data);
        }
        Loading.hide();
      },
    });
  }

  return (
    <div className="max-w800 m-l-xxl m-t-xxl p-l-xxl p-t-md profile-box">
      <h2 className="m-b-md">Basic information</h2>
      <Form
        form={form}
        name="update-info"
        layout="vertical"
        noValidate
        onFinish={updateInfo}
        autoComplete="off"
        scrollToFirstError
      >
        <Row>
          <Col md={12}>
            <Row>
              <Col md={24}>
                <Form.Item label="Full Name" name="fullName" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Phone Number" name="phoneNumber" rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                  <Input disabled readOnly={true} />
                </Form.Item>
              </Col>
              {isUpdatePassword && (
                <Col md={24}>
                  <Form.Item label="Password" name="password" rules={[{ required: true }]}>
                    <Input.Password placeholder="Your password" />
                  </Form.Item>
                  <Form.Item label="New Password" name="newPassword" rules={[{ required: true }]}>
                    <Input.Password placeholder="Your password" />
                  </Form.Item>
                  <Form.Item
                    label="Confirm Password"
                    name="passwordConfirmation"
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Your password" />
                  </Form.Item>
                </Col>
              )}
              <Col md={24}>
                <div className="m-b-md d-block" onClick={() => setUpdatePassword(!isUpdatePassword)}>
                  <span className="mat-icon-outline fs-14 m-r-xxxs top_2">edit</span>
                  <span className="bold fs-12 font-open">{isUpdatePassword ? 'Cancel' : 'Change password'}</span>
                </div>
                <button className="btn btn-primary m-t-sm">Save changes</button>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row>
              <Col className="p-l-md p-t-xxxs">
                <div className="float-start p-r-sm">
                  <label className="bold d-block">Profile Photo</label>
                  <UploadImageSingle
                    src={user.avatar}
                    onValue={value => {
                      avatarFile = value;
                    }}
                  />
                </div>
                <div className="m-l-sm">
                  <p className="fs-12">
                    Your photo will be visible to anyone you communicate with, such as your team and candidates. Square JPG or PNG
                    images work best.
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Profile;
