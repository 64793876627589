import ModalHeader from '@components/Modals/header';
import { Constants } from '@config/constant';
import { DateHelper } from '@helpers/dateHelper';
import { Toastr } from '@helpers/toastr';
import { PaginatedList, SearchPaymentPipeline } from '@models/base';
import { Company } from '@models/base/company';
import { CompanyDebtDetail, CompanyPaymentCandidate, CompanyPaymentValue, CompanyPaymentWallet } from '@models/referrer-payment';
import { CompanyPaymentWalletService } from '@services/company-payment-wallet.service';
import { CompanyPaymentService } from '@services/company-payment.service';
import { CompanyService } from '@services/company.service';
import { PipelinePaymentService } from '@services/pipeline-payment.service';
import { DatePicker, Modal, Popconfirm, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getUnixTime } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
const { Option } = Select;
const CompanyPaymentModal = forwardRef((props, ref) => {
  const companyPayService: CompanyPaymentService = CompanyPaymentService.instance;
  const companyService: CompanyService = CompanyService.instance;
  const companyWalletPaymentService: CompanyPaymentWalletService = CompanyPaymentWalletService.instance;
  const pipelinePaymentService: PipelinePaymentService = PipelinePaymentService.instance;

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [companyIdSelect, setCompanyIdSelect] = useState<number>(0);
  const [searchData, setSearchData] = useState<SearchPaymentPipeline>(new SearchPaymentPipeline(false, 10));
  const [list, setList] = useState<CompanyPaymentCandidate[]>([]);
  const [employeeDate, setEmployeeDate] = useState();
  const [confirmSelect, setConfirmSelect] = useState<any>({});
  const [paymentInfoValue, setPaymentInfoValue] = useState<CompanyPaymentValue>(new CompanyPaymentValue());
  const [paymentInfoWallet, setPaymentInfoWallet] = useState<CompanyPaymentWallet>(new CompanyPaymentWallet());
  const [companyDebtDetail, setCompanyDebtDetail] = useState<CompanyDebtDetail>(new CompanyDebtDetail());
  const [companyInfo, setCompanyInfo] = useState<Company>();
  const paymentStatus = Constants.PAYMENT_STATUS;
  const paymentStatusEnum = Constants.PAYMENT_STATUS_ENUM;
  const columnDefs: ColumnsType<CompanyPaymentCandidate> = useMemo<ColumnsType<CompanyPaymentCandidate>>(
    () => [
      {
        key: 'name',
        title: 'Name',
        render: value => {
          return (
            <span className="table-title">
              <Tooltip title={value.job?.title}>{value?.fullName || '-'} </Tooltip>
              {value.overdue && (
                <Tooltip title="Overdue Payment">
                  <span className="text-red mat-icon fs-16 top-6 m-l-xxs pointer">error</span>
                </Tooltip>
              )}
            </span>
          );
        },
      },
      {
        title: 'Employee Start-day',
        key: 'employee',
        dataIndex: 'employeeStartTime',
        render: data => {
          return <span>{DateHelper.formatByLocale(data, 'dd/MM/yyyy', 'en')}</span>;
        },
      },
      {
        title: 'Reward',
        key: 'reward',
        dataIndex: 'reward',
        render: data => {
          return <span>{data ? <NumberFormat value={data} displayType={'text'} thousandSeparator={true} /> : 0} VND</span>;
        },
      },
      {
        title: 'Reality Pay',
        key: 'reality',
        dataIndex: 'companyWithdrawFee',
        render: data => {
          return <span>{data ? <NumberFormat value={data} displayType={'text'} thousandSeparator={true} /> : 0} VND</span>;
        },
      },
      {
        key: 'status',
        title: 'Payment Status',
        dataIndex: 'companyStatus',
        render: value => {
          const status = paymentStatus.find(x => x.code === value);
          return <div dangerouslySetInnerHTML={{ __html: status?.html || '-' }} />;
        },
      },
      {
        title: '',
        key: 'action',
        render: data => {
          return (
            <div className="text-end">
              {data.companyStatus === paymentStatusEnum.New && (
                <Popconfirm title="Are you sure?" onConfirm={() => process(data)} okText="Yes" cancelText="No">
                  <button className="btn btn-small">Process</button>
                </Popconfirm>
              )}
              {data.companyStatus === paymentStatusEnum.Processing && data.canPaid && (
                <Popconfirm title="Are you sure?" onConfirm={() => savePaidConfirm(data)} okText="Yes" cancelText="No">
                  <button nz-popconfirm className="btn btn-primary btn-small">
                    Paid Confirm
                  </button>
                </Popconfirm>
              )}
            </div>
          );
        },
      },
    ],
    [],
  );

  useImperativeHandle(ref, () => ({
    showModal: (companyId: number) => {
      setCompanyIdSelect(companyId);
      setIsModalVisible(true);
      setSearchData(new SearchPaymentPipeline(false, 10));
    },
  }));

  useEffect(() => {
    if (companyIdSelect) {
      getInfoValueByCompany(companyIdSelect);
      getWalletByCompany(companyIdSelect);
      getDebtDetail(companyIdSelect);

      loadList();
      companyInfor();
    }
  }, [companyIdSelect]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onSelectedStatus(value: any) {
    searchData.status = value;
    search();
  }
  function onChangeEmployeeDate(value: any) {
    searchData.employeeStartTime = value ? getUnixTime(value.toDate()) : undefined;
    search();
  }

  const onKeyword = (event: any) => {
    searchData.keyword = event.target.value;
    const temp = { ...searchData };
    setSearchData(temp as SearchPaymentPipeline);
    search();
  };
  function getInfoValueByCompany(companyId: number) {
    companyPayService.getCompanyValueByCompanyId(companyId).subscribe((res: CompanyPaymentValue) => {
      if (res) {
        setPaymentInfoValue(res);
      }
    });
  }

  function getWalletByCompany(companyId: number) {
    companyWalletPaymentService.getCompanyWalletByCompanyId(companyId).subscribe((res: CompanyPaymentWallet) => {
      if (res) {
        setPaymentInfoWallet(res);
      }
    });
  }

  function getDebtDetail(companyId: number) {
    companyWalletPaymentService.getDebtDetail(companyId).subscribe((res: CompanyDebtDetail) => {
      if (res) {
        setCompanyDebtDetail(res);
      }
    });
  }

  function loadList(pagination?: any) {
    // if (pageNumber) {
    //   searchData.pageNumber = pageNumber;
    // }
    if (pagination && pagination.current) {
      searchData.pageNumber = pagination.current;
    }
    pipelinePaymentService.getByCompany(companyIdSelect, searchData).subscribe({
      next: (res: PaginatedList<CompanyPaymentCandidate>) => {
        if (res) {
          res.results.forEach((value, index) => {
            value.key = index;
          });
          setList(res.results);
          searchData.totalItems = res.totalItem;
        }
      },
    });
  }

  function companyInfor() {
    companyService.getInfo(companyIdSelect).subscribe((res: Company) => {
      if (res) {
        setCompanyInfo(res);
      }
    });
  }

  function search() {
    searchData.pageNumber = 1;
    loadList();
  }

  function handlerSort(option: any) {
    searchData.sortField = option.sortField;
    searchData.sortFieldType = option.sortFieldType;
    loadList(searchData.pageNumber);
  }

  // paidConfirm(item: CompanyPaymentCandidate) {
  //   item.visible = true;
  //   this.confirmSelect = item;
  //   this.confirmSelect.rewardTemp = +item.reward;
  // }

  function process(item: CompanyPaymentCandidate) {
    pipelinePaymentService
      .companyNewConfirm({ companyId: companyIdSelect, candidatePipeLineId: item.candidatePipeLineId })
      .subscribe((res: any) => {
        if (res && res.id > 0) {
          Toastr.success('Successfully');
          loadList();
        }
      });
  }

  function changeReward(value: boolean) {
    confirmSelect.rewardTemp = value ? Math.round(+confirmSelect.reward * 0.2) : confirmSelect.reward;
  }

  function savePaidConfirm(item: CompanyPaymentCandidate) {
    const body = {
      companyId: companyIdSelect,
      candidatePipeLineId: item.candidatePipeLineId,
    };
    pipelinePaymentService.companyPaidConfirm(body).subscribe((res: any) => {
      if (res && res.id > 0) {
        Toastr.success('Successfully');
        loadList();
        getInfoValueByCompany(companyIdSelect);
        getWalletByCompany(companyIdSelect);
        getDebtDetail(companyIdSelect);
      }
    });
  }

  return (
    <div>
      <Modal footer={null} closable={false} visible={isModalVisible} className="full-window">
        <ModalHeader>
          <button type="button" className="btn btn-medium m-r-xs" onClick={handleCancel}>
            Close
          </button>
        </ModalHeader>
        <div className="modal-frame theme-default">
          <div className="wrapper-content max-w1200 limit-modal full-height">
            <h1 className="m-b-xxs">{companyInfo?.name}</h1>
            <h2 className="m-b-md">{companyInfo?.addresses}</h2>
            <ul className="m-b-md">
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Contact Email</span>
                <p>{companyInfo?.email || '-'}</p>
              </li>
            </ul>
            <h2 className="m-t-xxl m-b-sm">Payment Information</h2>
            <ul className="m-b-md">
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Total Employee</span>
                <p>
                  {paymentInfoValue?.employee || 0} {paymentInfoValue?.employee > 1 ? ' Candidates' : ' Candidate'}
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Paid Employee</span>
                <p>
                  {paymentInfoValue?.employeePaid || 0} {paymentInfoValue?.employeePaid > 1 ? ' Candidates' : ' Candidate'}
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Unpaid Employee</span>
                <p>
                  {paymentInfoValue?.employee - paymentInfoValue?.employeePaid}
                  {paymentInfoValue?.employee - paymentInfoValue?.employeePaid > 1 ? ' Candidates' : ' Candidate'}
                </p>
              </li>
            </ul>
            <ul>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Total Payment</span>
                <p>
                  <NumberFormat value={paymentInfoWallet?.income} displayType={'text'} thousandSeparator={true} /> VND
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Paid Payment</span>
                <p>
                  <NumberFormat value={paymentInfoWallet?.withdrawFee} displayType={'text'} thousandSeparator={true} /> VND
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Unpaid Payment</span>
                <p>
                  <NumberFormat value={paymentInfoWallet?.debt} displayType={'text'} thousandSeparator={true} /> VND
                  {paymentInfoWallet && paymentInfoWallet.debt && (
                    <Tooltip
                      className="mat-icon-outline fs-15 m-l-xs top_2 pointer"
                      title={
                        <div>
                          <div className="row">
                            <div className="col-7">New Payment</div>
                            <div className="col-5">
                              {companyDebtDetail?.new ? (
                                <NumberFormat value={companyDebtDetail?.new} displayType={'text'} thousandSeparator={true} />
                              ) : (
                                0
                              )}{' '}
                              VND
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-7">Processing Payment</div>
                            <div className="col-5">
                              {companyDebtDetail?.processing ? (
                                <NumberFormat
                                  value={companyDebtDetail?.processing}
                                  displayType={'text'}
                                  thousandSeparator={true}
                                />
                              ) : (
                                0
                              )}{' '}
                              VND
                            </div>
                          </div>
                        </div>
                      }
                      placement="bottom"
                      overlayClassName="min-w350"
                    >
                      <span className="mat-icon-outline fs-16 top_3 m-l-xxs pointer"> info</span>
                    </Tooltip>
                  )}
                </p>
              </li>
            </ul>
            <h2 className="m-t-xxl m-b-sm">Candidate</h2>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group width-300 m-b-none m-r-sm d-inline-block">
                  <input
                    placeholder="Search Name or Email"
                    className="form-control have-icon small"
                    value={searchData.keyword}
                    onChange={onKeyword}
                  />
                  <span className="form-control-icon transform pointer">
                    <span className="mat-icon-outline fs-20">search</span>
                  </span>
                </div>
                <div className="m-r-sm d-inline-block">
                  <div className="form-group small custom-picker">
                    <DatePicker
                      key="p-period"
                      suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                      format="MMM-yyyy"
                      picker="month"
                      defaultValue={employeeDate}
                      placeholder="Employee Month"
                      onChange={onChangeEmployeeDate}
                    ></DatePicker>
                  </div>
                </div>
                <div className="dropdown m-r-sm d-inline-block">
                  <div className="form-group small">
                    <Select
                      suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                      allowClear={true}
                      value={searchData.status}
                      onChange={onSelectedStatus}
                      placeholder="Payment status"
                    >
                      {paymentStatus.map(item => (
                        <Option key={item.code} value={item.code}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="col-md-12 m-tb-lg custom-table">
                <Table
                  columns={columnDefs}
                  dataSource={list}
                  onChange={loadList}
                  pagination={{
                    hideOnSinglePage: true,
                    total: searchData.totalItems,
                    pageSize: searchData.pageSize,
                    current: searchData.pageNumber,
                    showSizeChanger: false,
                  }}
                ></Table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default CompanyPaymentModal;
