import { Constants } from '@config/constant';
import { DateHelper } from '@helpers/dateHelper';
import { Loading } from '@helpers/loading';
import WebHelper from '@helpers/webHelper';
import { PaginatedList, SearchRefer } from '@models/base';
import { ReferrerPayment } from '@models/referrer-payment';
import { ReferrerPaymentService } from '@services/refererr.service';
import { DatePicker, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getUnixTime } from 'date-fns';
import moment from 'moment-timezone';
import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, Subject } from 'rxjs';
import ReferrerPaymentModal from './modals/referrer-payment-modal';
const { Option } = Select;
interface PaymentPageProps {}
const subject = new Subject<any>();
const searchDataLocal: SearchRefer = new SearchRefer(true);
const PaymentPage: FunctionComponent<PaymentPageProps> = () => {
  const modalChild = useRef<any>(null);
  const paymentStatusEnum = Constants.PAYMENT_STATUS_ENUM;
  const referrerPayService: ReferrerPaymentService = ReferrerPaymentService.instance;
  const paymentStatus = Constants.PAYMENT_STATUS;
  const [periodDate, setPeriodDate] = useState(moment());
  const [list, setList] = useState<ReferrerPayment[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [searchData, setSearchData] = useState<SearchRefer>(new SearchRefer(true));
  const columnDefs: ColumnsType<ReferrerPayment> = useMemo<ColumnsType<ReferrerPayment>>(
    () => [
      {
        key: 'name',
        title: 'Referrers',
        render: value => {
          return (
            <span
              className="table-title pointer"
              onClick={() => {
                modalChild.current?.showModal(value.userId, value.status);
              }}
            >
              {value.user?.fullName || '-'}
            </span>
          );
        },
      },
      {
        key: 'status',
        title: 'Payment Status',
        dataIndex: 'status',
        render: value => {
          const status = paymentStatus.find(x => x.code === value);
          return <div dangerouslySetInnerHTML={{ __html: status?.html || '-' }} />;
        },
      },
      {
        title: 'Payment Period',
        key: 'period',
        render: data => {
          if (data && data.period)
            return (
              <div>
                {DateHelper.formatByLocale(data.period, 'MMM, yyyy', 'en')}
                {data.period && data.canPaid && data.status !== paymentStatusEnum.Paid && (
                  <Tooltip title="Priority payment period" placement="top">
                    <span className="mat-icon-outline fs-16 top_3 m-l-xxs pointer"> info</span>
                  </Tooltip>
                )}
              </div>
            );
          return '-';
        },
      },
      {
        title: 'Unpaid Refers',
        key: 'unpaid',
        render: data => {
          return <span>{data.employee - data.employeePaid || '-'}</span>;
        },
      },
      {
        title: 'Probation Hire',
        key: 'probationHire',
        dataIndex: 'probationHire',
        render: data => {
          return <span>{data || '-'}</span>;
        },
      },
      {
        title: 'Company employee',
        key: 'employee',
        dataIndex: 'employee',
        render: data => {
          return <span>{data || '-'}</span>;
        },
      },
      {
        title: 'Email',
        key: 'email',
        render: data => {
          return <span>{data.user?.email}</span>;
        },
      },
    ],
    [],
  );
  // const paymentStatusEnum = Constants.PAYMENT_STATUS_ENUM;
  useEffect(() => {
    loadList();
  }, []);

  useEffect(() => {
    const subcribe = subject.pipe(debounceTime(500)).subscribe({
      next: v => {
        return search();
      },
    });
    return () => {
      WebHelper.unSubscribe(subcribe);
    };
  }, []);

  function loadList(pagination?: any) {
    if (pagination && pagination.current) {
      searchDataLocal.pageNumber = pagination.current;
    }
    Loading.show();
    referrerPayService.getAll<PaginatedList<ReferrerPayment>>(searchDataLocal).subscribe(result => {
      if (result) {
        result.results.forEach((value, index) => {
          value.key = index;
        });
        setList(result.results);
        searchDataLocal.totalItems = result.totalItem;
        searchDataLocal.pageNumber = searchData.pageNumber;
        setSearchData(searchDataLocal);
        Loading.hide();
      }
    });
  }

  const search = () => {
    searchDataLocal.pageNumber = 1;
    loadList();
  };

  function onSelectedStatus(value: any) {
    searchDataLocal.status = value;
    search();
  }

  function onChangePeriod(value: any) {
    searchDataLocal.period = value ? getUnixTime(value.toDate()) : undefined;
    search();
  }

  const onKeyword = (event: any) => {
    searchDataLocal.keyword = event.target.value;
    setKeyword(event.target.value);
    subject.next(searchData);
  };

  return (
    <div className="custom-scroll p-md">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group width-300 m-b-none m-r-sm d-inline-block">
            <input
              placeholder="Search Name or Email"
              className="form-control have-icon small"
              value={keyword}
              onChange={onKeyword}
            />
            <span className="form-control-icon transform pointer">
              <span className="mat-icon-outline fs-20">search</span>
            </span>
          </div>
          <div className="dropdown m-r-sm d-inline-block">
            <div className="form-group small">
              <Select
                suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                allowClear={true}
                value={searchData.status}
                onChange={onSelectedStatus}
                placeholder="Payment status"
              >
                {paymentStatus.map(item => (
                  <Option key={item.code} value={item.code}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="m-r-sm d-inline-block">
            <div className="form-group small custom-picker">
              <DatePicker
                key="p-period"
                suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                format="MMM-yyyy"
                picker="month"
                defaultValue={periodDate}
                // defaultValue={searchData.}
                placeholder="Payment Period"
                onChange={onChangePeriod}
              ></DatePicker>
            </div>
          </div>
        </div>
        <div className="col-md-12 m-t-lg custom-table">
          <Table
            columns={columnDefs}
            dataSource={list}
            onChange={loadList}
            pagination={{
              hideOnSinglePage: true,
              total: searchData.totalItems,
              pageSize: searchData.pageSize,
              current: searchData.pageNumber,
              showSizeChanger: false,
            }}
          ></Table>
        </div>
      </div>
      <ReferrerPaymentModal ref={modalChild} />
    </div>
  );
};

export default PaymentPage;
