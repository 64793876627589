import { GeneratePassword } from '@helpers/generatePassword';
import { getEncryptData, getMessage, graphSqlClient } from '@helpers/https';
import { Loading } from '@helpers/loading';
import { Toastr } from '@helpers/toastr';
import { PaginatedList } from '@models/base';
import { UserModel } from '@models/users/user';
import { UserService } from '@services/user.service';
import { put, select, takeLatest } from 'redux-saga/effects';
import { userActions as actions } from '.';
import { DELETE_USER, GET_USERS } from './query';
import { selectSearchData, selectUserData } from './selectors';

const userService = UserService.instance;
function* getUserList(): any {
  const searchData = yield select(selectSearchData);
  try {
    Loading.show();
    // const list: PaginatedList<UserModel> = yield userService.getList(searchData).execute();
    const respone = yield graphSqlClient.query({
      query: GET_USERS,
      variables: searchData,
      fetchPolicy: 'network-only',
      context: { clientName: 'authenLink' },
    });
    const result = getEncryptData<PaginatedList<UserModel>>(respone);
    yield put(actions.userLoaded(result));
    Loading.hide();
  } catch (error) {
    //
  }
}

function* resetPassword(): any {
  const user: UserModel = yield select(selectUserData);
  const pw = yield GeneratePassword.generatePw({
    length: 10,
    numbers: true,
  });
  const result = yield userService.resetPassword(user._id, pw).execute();
  Toastr.message(result.id, result.message);
}
function* deleteUser(): any {
  const user: UserModel = yield select(selectUserData);
  // const result: ApiResponse = yield userService.deleteUser(user._id).execute();
  Loading.show();
  const result = yield graphSqlClient.mutate({
    mutation: DELETE_USER,
    variables: { id: user._id },
    context: { clientName: 'authenLink' },
  });
  const msg = getMessage(result);
  Toastr.messageCome(msg);
  Loading.hide();
  if (msg.id > 0) {
    yield put(actions.reload());
  }
}
// watcher saga
export function* userFromSaga() {
  yield takeLatest(actions.loadUser.type, getUserList);
  yield takeLatest(actions.resetPassword.type, resetPassword);
  yield takeLatest(actions.deleteUser.type, deleteUser);
}
// Export all sagas
