import WebHelper from '@helpers/webHelper';
import { PaginatedList, SearchCompanySetting, SearchSimple } from '@models/base';
import { Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, Subject } from 'rxjs';
import { CompanyService } from '@services/company.service';
import { Company } from '@models/base/company';
import { useQuery } from '@apollo/client';
import { LIST_COMPANY_SETTING } from './query';
import { CompanySetting } from '@models/api-response.model';
import AddUpdateModal from './add-update-modal';
const modelChanged: Subject<any> = new Subject<any>();
const { Option } = Select;

const KeySettingPage = () => {
  const companyService: CompanyService = CompanyService.instance;
  const [skip, setSkip] = useState(true);
  const [searchCompany, setSearchCompany] = useState<SearchSimple>(new SearchSimple());
  const [companys, setCompanys] = useState<Company[]>([]);
  const [list, setList] = useState<CompanySetting[]>([]);
  const [searchData, setSearchData] = useState<SearchCompanySetting>(new SearchCompanySetting());
  const [keyword, setKeyword] = useState<string>('');
  const [total, setTotal] = useState<number>(0);
  const modalChild = useRef<any>();
  const columnDefs: ColumnsType<CompanySetting> = useMemo<ColumnsType<CompanySetting>>(
    () => [
      {
        key: 'key',
        title: 'Key',
        dataIndex: 'key',
        render: value => {
          return <span>{value || '-'}</span>;
        },
      },
      {
        key: 'value',
        title: 'Value',
        dataIndex: 'value',
        render: value => {
          return <span>{value || '-'}</span>;
        },
      },
      {
        key: 'action',
        render: data => {
          return (
            <div className="text-end">
              <button
                className="btn btn-outline btn-small"
                onClick={() => {
                  modalChild.current?.showModal(data);
                }}
              >
                Edit
              </button>
            </div>
          );
        },
      },
    ],
    [],
  );

  const { data, refetch } = useQuery(LIST_COMPANY_SETTING, {
    variables: searchData,
    skip,
    // fetchPolicy: 'cache-first',
    // fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data && data.countCompanySettings) {
      setList(data.companySettings);
      // searchData.totalItems = data.countCompanySettings;
      setTotal(data.countCompanySettings);
    } else {
      setList([]);
      // searchData.totalItems = 0;
      setTotal(0);
    }
  }, [data]);

  useEffect(() => {
    loadCompanyList();
    const subcribe = modelChanged.pipe(debounceTime(500)).subscribe({
      next: v => {
        // return search();
        // console.log('a', v);
        // refetch(v);
        setSearchData(v as SearchCompanySetting);
      },
    });
    return () => {
      WebHelper.unSubscribe(subcribe);
    };
  }, []);

  useEffect(() => {
    return () => {
      setCompanys([]);
      setSearchData(new SearchCompanySetting(true));
    };
  }, []);

  //   useEffect(() => {
  //     console.log('d');
  //     search();
  //   }, [searchData]);

  function loadList(pagination?: any) {
    if (pagination && pagination.current) {
      searchData.pageNumber = pagination.current;
    }
    const searchTemp = { ...searchData };
    setSearchData(searchTemp as SearchCompanySetting);
  }

  //   const search = () => {
  //     searchData.pageNumber = 1;
  //     refetch();
  //   };

  const onKeyword = (event: any) => {
    setKeyword(event.target.value);
    const searchTemp = { ...searchData };
    searchTemp.keyword = event.target.value;
    searchTemp.pageNumber = 1;
    modelChanged.next(searchTemp);
  };

  function onSelectedCompany(value: any) {
    searchData.companyId = value;
    searchData.pageNumber = 1;
    const searchTemp = { ...searchData };
    setSearchData(searchTemp as SearchCompanySetting);
  }

  function loadCompanyList() {
    companyService.getDropdown<PaginatedList<Company>>(searchCompany).subscribe(resp => {
      setCompanys([...companys, ...resp.results]);
      searchCompany.totalItems = resp.totalItem;
      if (!searchData.companyId) {
        searchData.companyId = resp.results[0].id;
        const searchTemp = { ...searchData };
        setSkip(false);
        setSearchData(searchTemp as SearchCompanySetting);
      }
    });
  }

  function loadMoreCompany(event: any): void {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom) {
      searchCompany.pageNumber++;
      if (searchCompany.valid) {
        loadCompanyList();
      }
    }
  }

  return (
    <div className="custom-scroll p-md">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group width-300 m-b-none m-r-sm d-inline-block">
            <input
              placeholder="Search Name or Email"
              className="form-control have-icon small"
              value={keyword}
              onChange={onKeyword}
            />
            <span className="form-control-icon transform pointer">
              <span className="mat-icon-outline fs-20">search</span>
            </span>
          </div>
          <div className="dropdown m-r-sm d-inline-block">
            <div className="form-group small">
              <Select
                suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                value={searchData.companyId}
                onChange={onSelectedCompany}
                onPopupScroll={loadMoreCompany}
                placeholder="All company"
              >
                {companys.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
          <div className="d-inline-block">
            <button
              onClick={() => {
                modalChild.current?.showModal();
              }}
              className="btn btn-primary"
            >
              Add
            </button>
          </div>
        </div>
        <div className="col-md-12 m-t-lg custom-table">
          <Table
            columns={columnDefs}
            dataSource={list}
            onChange={loadList}
            pagination={{
              hideOnSinglePage: true,
              total,
              pageSize: searchData.pageSize,
              current: searchData.pageNumber,
              showSizeChanger: false,
            }}
          ></Table>
        </div>
      </div>
      <AddUpdateModal ref={modalChild} refreshList={refetch} />
    </div>
  );
};
export default KeySettingPage;
