import { FunctionComponent } from 'react';
import { NavLink } from 'react-router-dom';
interface MenuLineProps {
  menus: Array<any>;
}
const MenuLine: FunctionComponent<MenuLineProps> = ({ menus }: any) => {
  return (
    <ul className="nav nav-tabs menu-line">
      {menus.map((menu: any) => (
        <li className="nav-item" key={menu.id}>
          <NavLink to={menu.url || ''} className={({ isActive }) => (isActive ? 'active-custom nav-link' : 'nav-link')}>
            {menu.title}
          </NavLink>
        </li>
      ))}
    </ul>
  );
};

export default MenuLine;
