import { FunctionComponent } from 'react';
import { Container, Row } from 'react-bootstrap';

interface PageWrapperProps {
  children: any;
}

const PageWrapper: FunctionComponent<PageWrapperProps> = props => {
  return (
    <Container className="wrapper-content p-md">
      <Row>{props.children}</Row>
    </Container>
  );
};

export default PageWrapper;
