import { BaseService } from '@helpers/https';
import { AxiosResponse } from 'axios';

export class AuthenService extends BaseService {
  private static _instance: AuthenService;
  static get instance() {
    if (!this._instance) this._instance = new AuthenService();
    return this._instance;
  }
  hostUrl = this.host + '/auth/user';
  login(data: any) {
    const url = '/a-login';
    return this.post<any>(url, data);
  }
  getLoginIfo() {
    const url = '/get-login-info';
    return this.get(url);
  }
  logout() {
    const url = '/logout';
    return this.delete(url);
  }

  async getCurrentExpiredTime(): Promise<number> {
    const url = 'get-expired-time';
    const data = await this.get<AxiosResponse>(url).execute();
    if (data && typeof data === 'number') {
      return data;
    }
    return -1;
  }
}
