import { BaseService } from '@helpers/https';

export class JobService extends BaseService {
  hostUrl = this.host + '/core/admin-jobs';
  private static _instance: JobService;
  static get instance() {
    if (!this._instance) this._instance = new JobService();
    return this._instance;
  }
}
