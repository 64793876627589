export class FileUpload {
  fileName?: string;
  mimetype?: string;
  file?: string;
  old?: any;
}
export class FileUploaded {
  fileName?: string;
  mimetype?: string;
  url?: string;
}
export class ResizeFile {
  fileName?: string;
  mimetype?: string;
  file?: any;
  url?: any;
  type?: string;
  old?: string;
  folder?: string;
  uid?: string;
  isOldImage?: boolean;
  constructor() {
    this.fileName = '';
    this.mimetype = '';
    this.file = null;
  }
}
