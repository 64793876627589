/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-extend-native */
interface Array<T> {
  isNotNull(): boolean;
}
Array.prototype.isNotNull = function () {
  return this && this.length > 0;
};
Object.defineProperty(Array.prototype, 'move', {
  value(from: any, to: any) {
    this.splice(to, 0, this.splice(from, 1)[0]);
    return this;
  },
});
Object.defineProperty(Object.prototype, 'getFileName2', {
  value(type: string) {
    if (this && this.length) {
      const file = this.find((x: any) => x.type === type);
      if (file && file.constructor === Object) {
        return file.url;
      }
      return undefined;
    }
    return undefined;
  },
});

Object.defineProperty(Object.prototype, 'getFileNames2', {
  value(type: string) {
    if (this && this.length) {
      const files = this.filter((x: any) => x.type === type);
      if (files && files.length) {
        const fileNames = files.map((x: any) => x.url);
        return fileNames;
      }
      return undefined;
    }
    return undefined;
  },
});

Object.defineProperty(Object.prototype, 'isNotNullOrEmpty', {
  value() {
    if (this && Object.keys(this).length) {
      return true;
    }
    return false;
  },
});
Object.defineProperty(Object.prototype, 'addPlacholder', {
  value(str: string) {
    this.unshift({ id: 0, name: str });
  },
});

Object.defineProperty(Object.prototype, 'compareObject', {
  value(obj2: any) {
    return JSON.stringify(this) === JSON.stringify(obj2);
  },
});
Object.defineProperty(document.body.style, 'transform', {
  value: () => {
    return {
      enumerable: true,
      configurable: true,
    };
  },
});
Object.defineProperty(document.body.style, 'transformOrigin', {
  value: () => {
    return {
      enumerable: true,
      configurable: true,
    };
  },
});

Object.defineProperty(Array.prototype, 'replaceMany', {
  value(params: any[], ...options: any) {
    let data = JSON.stringify(this);
    params.forEach(element => {
      data = data.replace(new RegExp(element.oldvalue, 'g'), element.newvalue);
    });
    const objData = JSON.parse(data);
    if (options && options.length) {
      options.forEach((opt: any) => {
        objData.forEach((item: any) => {
          addOption(item, opt);
        });
      });
    }
    return objData;
  },
});

function addOption(item: any, opt: any) {
  if (item.children) {
    item.children.forEach((child: any) => {
      if (child.children) {
        addOption(child, opt);
      } else {
        child[opt.name] = opt.value;
      }
    });
  } else {
    item[opt.name] = opt.value;
  }
}

interface String {
  removeVietnamese(): string;
  clear(): string;
  toUrl(): string;
  trimLft(charlist: string): string;
  trimRht(charlist: string): string;
  trym(charlist: string): string;
}
String.prototype.removeVietnamese = function () {
  if (this) {
    return accentsTidy(this.toString());
  }
  return '';
};
String.prototype.toUrl = function () {
  if (this) {
    return friendlyUrl(this.toString());
  }
  return '';
};
// tslint:disable-next-line:only-arrow-functions
String.prototype.clear = function () {
  return '';
};
String.prototype.trimLft = function (charlist: string) {
  if (charlist === undefined) {
    charlist = 's';
  }

  return this.replace(new RegExp('^[' + charlist + ']+'), '');
};
String.prototype.trimRht = function (charlist: string) {
  if (charlist === undefined) {
    charlist = 's';
  }

  return this.replace(new RegExp('[' + charlist + ']+$'), '');
};
String.prototype.trym = function (charlist) {
  return this.trimLft(charlist).trimRht(charlist);
};
function accentsTidy(str: string) {
  str = str.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ/g, 'd');
  // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'| |\"|\&|\#|\[|\]|~|$|_/g, '-');

  // str = str.replace(/-+-/g, '-'); //thay thế 2- thành 1-
  // str = str.replace(/^\-+|\-+$/g, '');

  return str;
}

function friendlyUrl(str: string) {
  str = accentsTidy(str);
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;';
  const to = 'aaaaaeeeeeiiiiooooouuuunc------';
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
}
