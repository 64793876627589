import XImage from '@components/Image';
import ModalHeader from '@components/Modals/header';
import Sort from '@components/Sort';
import { Constants } from '@config/constant';
import { DateHelper } from '@helpers/dateHelper';
import { Toastr } from '@helpers/toastr';
import { PaginatedList, SearchPaymentPipeline, SearchSimple } from '@models/base';
import { Company } from '@models/base/company';
import { SortItem } from '@models/enums';
import { PaymentCandidate, PaymentInfo, ReferrerPayment } from '@models/referrer-payment';
import { UserModel } from '@models/users/user';
import { CompanyService } from '@services/company.service';
import { PipelinePaymentService } from '@services/pipeline-payment.service';
import { ReferrerPaymentService } from '@services/refererr.service';
import { UserService } from '@services/user.service';
import { WalletService } from '@services/wallet.service';
import { DatePicker, Modal, Popconfirm, Popover, Select, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { getUnixTime } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import NumberFormat from 'react-number-format';
const { Option } = Select;
const ReferrerPaymentModal = forwardRef((props, ref) => {
  const referrerPayService: ReferrerPaymentService = ReferrerPaymentService.instance;
  const companyService: CompanyService = CompanyService.instance;
  const walletService: WalletService = WalletService.instance;
  const pipelinePaymentService: PipelinePaymentService = PipelinePaymentService.instance;
  const userService: UserService = UserService.instance;

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [userIdSelect, setUserIdSelect] = useState<string>();
  const [statusSelect, setStatusSelect] = useState<string>();
  const [searchData, setSearchData] = useState<SearchPaymentPipeline>(new SearchPaymentPipeline(false, 10));
  const [sort, setSort] = useState<SortItem>(new SortItem());
  const [searchCompany, setSearchCompany] = useState<SearchSimple>(new SearchSimple());
  const [list, setList] = useState<PaymentCandidate[]>([]);
  const [companys, setCompanys] = useState<Company[]>([]);
  const [employeeDate, setEmployeeDate] = useState();
  const [probationDate, setProbationDate] = useState();
  const [confirmSelect, setConfirmSelect] = useState<any>({});
  const [paymentInfo, setPaymentInfo] = useState<PaymentInfo>(new PaymentInfo());
  const [referrerPayment, setReferrerPayment] = useState<ReferrerPayment>(new ReferrerPayment());
  const [referrerInfo, setReferrerInfo] = useState<UserModel>(new UserModel());
  const paymentStatus = Constants.PAYMENT_STATUS;
  const paymentStatusEnum = Constants.PAYMENT_STATUS_ENUM;
  const clickSort = (option: any) => {
    const sortTemp = { ...sort };
    sortTemp.sortField = option.sortField;
    sortTemp.sortFieldType = option.sortFieldType;
    setSort(sortTemp as SortItem);
  };
  const columnDefs: ColumnsType<PaymentCandidate> = useMemo<ColumnsType<PaymentCandidate>>(
    () => [
      {
        key: 'name',
        title: <Sort title="Name" field="name" clickSort={clickSort} />,
        render: value => {
          return (
            <span className="table-title">
              <Tooltip title={value.job?.title}>{value?.fullName || '-'}</Tooltip>
              {value.overdue && (
                <Tooltip title="Overdue Payment">
                  <span className="text-red mat-icon fs-16 top-6 m-l-xxs pointer">error</span>
                </Tooltip>
              )}
            </span>
          );
        },
      },
      {
        key: 'name',
        title: <Sort title="Company Name" field="company" clickSort={clickSort} />,
        render: value => {
          return <span className="table-title pointer">{value.company?.name || '-'}</span>;
        },
      },
      {
        title: <Sort title="Probation Start-day" field="probationHire" clickSort={clickSort} />,
        key: 'probation',
        dataIndex: 'probationStartTime',
        render: data => {
          return <span>{DateHelper.formatByLocale(data, 'dd/MM/yyyy', 'en')}</span>;
        },
      },
      {
        title: <Sort title="Employee Start-day" field="employee" clickSort={clickSort} />,
        key: 'employee',
        dataIndex: 'employeeStartTime',
        render: data => {
          return <span>{DateHelper.formatByLocale(data, 'dd/MM/yyyy', 'en')}</span>;
        },
      },
      {
        title: <Sort title="Reward" field="reward" clickSort={clickSort} />,
        key: 'reward',
        dataIndex: 'reward',
        render: data => {
          return <span>{data ? <NumberFormat value={data} displayType={'text'} thousandSeparator={true} /> : 0} VND</span>;
        },
      },
      {
        title: <Sort title="Reality Pay" field="paid" clickSort={clickSort} />,
        key: 'reality',
        dataIndex: 'referWithdrawFee',
        render: data => {
          return <span>{data ? <NumberFormat value={data} displayType={'text'} thousandSeparator={true} /> : 0} VND</span>;
        },
      },
      {
        key: 'status',
        title: <Sort title="Payment Status" field="status" clickSort={clickSort} />,
        dataIndex: 'referStatus',
        render: value => {
          const status = paymentStatus.find(x => x.code === value);
          return <div dangerouslySetInnerHTML={{ __html: status?.html || '-' }} />;
        },
      },
      {
        title: '',
        key: 'action',
        render: data => {
          return (
            <div className="text-end">
              {data.referStatus === paymentStatusEnum.New && (
                <Popconfirm title="Are you sure?" onConfirm={() => process(data)} okText="Yes" cancelText="No">
                  <button className="btn btn-small">Process</button>
                </Popconfirm>
              )}
              {data.referStatus === paymentStatusEnum.Processing && data.canPaid && (
                <Popover content={content} trigger="click" visible={data.visible}>
                  <button
                    className="btn btn-primary btn-small"
                    onClick={() => {
                      paidConfirm(data);
                    }}
                  >
                    Paid Confirm
                  </button>
                </Popover>
              )}
            </div>
          );
        },
      },
    ],
    [confirmSelect],
  );

  useImperativeHandle(ref, () => ({
    showModal: (userId: string, status: string) => {
      setCompanys([]);
      setUserIdSelect(userId);
      setStatusSelect(status);
      setIsModalVisible(true);
      setSearchData(new SearchPaymentPipeline(false, 10));
    },
  }));

  useEffect(() => {
    if (userIdSelect) {
      loadCompanyList();
      getReferValueByUser(userIdSelect);
      getWalletByUser(userIdSelect);
      loadList();
      referInfor();
    }
  }, [userIdSelect]);

  useEffect(() => {
    searchData.sortField = sort.sortField;
    searchData.sortFieldType = sort.sortFieldType;
    loadList();
  }, [sort]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function onSelectedStatus(value: any) {
    searchData.status = value;
    search();
  }
  function onSelectedCompany(value: any) {
    searchData.companyId = value;
    search();
  }

  const onKeyword = (event: any) => {
    searchData.keyword = event.target.value;
    const temp = { ...searchData };
    setSearchData(temp as SearchPaymentPipeline);
    search();
  };
  function getReferValueByUser(userId: string) {
    referrerPayService.getReferValueByUser(userId).subscribe((res: ReferrerPayment) => {
      if (res) {
        setReferrerPayment(res);
      }
    });
  }

  function getWalletByUser(userId: string) {
    walletService.getWalletByUser(userId).subscribe((res: PaymentInfo) => {
      if (res) {
        setPaymentInfo(res);
      }
    });
  }

  function loadList(pagination?: any) {
    if (pagination && pagination.current) {
      searchData.pageNumber = pagination.current;
    }
    pipelinePaymentService.getByUser(userIdSelect!, searchData).subscribe((res: PaginatedList<PaymentCandidate>) => {
      if (res) {
        res.results.forEach((value, index) => {
          value.key = index;
        });
        setList(res.results);
        const searchDataTemp = { ...searchData };
        searchDataTemp.totalItems = res.totalItem;
        setSearchData(searchDataTemp as SearchPaymentPipeline);
      }
    });
  }

  function referInfor() {
    userService.userRefer(userIdSelect!).subscribe((res: UserModel) => {
      if (res) {
        setReferrerInfo(res);
      }
    });
  }

  function search() {
    searchData.pageNumber = 1;
    loadList();
  }

  function loadCompanyList() {
    companyService.getDropdown<PaginatedList<Company>>(searchCompany).subscribe(resp => {
      setCompanys([...companys, ...resp.results]);
      searchCompany.totalItems = resp.totalItem;
    });
  }

  function loadMoreCompany(event: any): void {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom) {
      searchCompany.pageNumber++;
      if (searchCompany.valid) {
        loadCompanyList();
      }
    }
  }

  function paidConfirm(item: PaymentCandidate) {
    item.visible = true;
    let newData = { ...item };
    newData.isCheck = false;
    newData.rewardTemp = +item.reward;
    setConfirmSelect(newData);
  }

  function process(item: PaymentCandidate) {
    pipelinePaymentService
      .newConfirm({ userId: userIdSelect!, candidatePipeLineId: item.candidatePipeLineId })
      .subscribe((res: any) => {
        if (res && res.id > 0) {
          Toastr.success('Successfully');
          loadList();
        }
      });
  }

  function cancelConfirm() {
    const item = { ...confirmSelect };
    item.visible = false;
    item.isCheck = false;
    let itemInList = list.findIndex(itemL => itemL.id === item.id);
    if (itemInList >= 0) {
      const newList = [...list];
      newList[itemInList] = item;
      setList(newList);
    }
    setConfirmSelect(item);
  }

  function onChangeEmployeeDate(value: any) {
    searchData.employeeStartTime = value ? getUnixTime(value.toDate()) : undefined;
    search();
  }

  function onChangeProbationDate(value: any) {
    searchData.probationStartTime = value ? getUnixTime(value.toDate()) : undefined;
    search();
  }

  function changeReward(value: any) {
    const checked = value.target.checked;
    const temp = { ...confirmSelect };
    temp.rewardTemp = checked ? Math.round(+temp.reward * 0.2) : temp.reward;
    temp.isCheck = checked;
    setConfirmSelect(temp);
  }

  function savePaidConfirm(event: any) {
    event.preventDefault();
    const body = {
      userId: userIdSelect!,
      candidatePipeLineId: confirmSelect.candidatePipeLineId,
      isOverBalance: confirmSelect.isCheck || false,
    };
    pipelinePaymentService.paidConfirm(body).subscribe((res: any) => {
      if (res && res.id > 0) {
        Toastr.success('Successfully');
        cancelConfirm();
        loadList();
        getReferValueByUser(userIdSelect!);
        getWalletByUser(userIdSelect!);
      }
    });
  }

  const returnStatus = (status: String) => {
    const statusF = paymentStatus.find(x => x.code === status);
    return <span className="m-l-xxs fs-14 top-1" dangerouslySetInnerHTML={{ __html: statusF?.html || '-' }} />;
  };

  const content = (
    <div className="width-350">
      <form onSubmit={savePaidConfirm}>
        <div className="form-group">
          <label className="required">Paid Reward Confirmation</label>
          <input className="form-control" name="pai-reward" value={confirmSelect.rewardTemp} disabled required />
          <span className="form-control-icon inherit"> VND </span>
        </div>
        <label className="container-check small m-b-sm">
          <input type="checkbox" name="include" checked={confirmSelect.isCheck} onChange={changeReward} />
          <span className="checkmark"></span>
          <label className="m-l-md top-2">Pay 20% of the reward</label>
        </label>
        <div className="text-end border-footer">
          <button type="button" className="btn m-r-xs" onClick={cancelConfirm}>
            Cancel
          </button>
          <button className="btn btn-primary">Save</button>
        </div>
      </form>
    </div>
  );
  return (
    <div>
      <Modal footer={null} closable={false} visible={isModalVisible} className="full-window">
        <ModalHeader>
          <button type="button" className="btn btn-medium m-r-xs" onClick={handleCancel}>
            Close
          </button>
        </ModalHeader>
        <div className="modal-frame theme-default">
          <div className="wrapper-content max-w1200 limit-modal full-height">
            <h1 className="m-b-xxs">
              {referrerInfo?.fullName}
              {statusSelect && <span className="m-l-xxs fs-14 top-3">{returnStatus(statusSelect)}</span>}
            </h1>
            <h2 className="m-b-xxl">{referrerInfo?.email}</h2>
            <div className="box-avatar">
              {referrerInfo?.avatar && <XImage url={referrerInfo?.avatar} alt="avatar" type="CDN" />}
            </div>
            <ul className="m-b-md">
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Name</span>
                <p>{referrerInfo?.fullName}</p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Phone</span>
                <p>{referrerInfo?.phoneNumber || '-'}</p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Contact Email</span>
                <p>{referrerInfo?.email || '-'}</p>
              </li>
            </ul>
            <ul>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Total Refers</span>
                <p>
                  {referrerPayment?.refers || 0} {referrerPayment?.refers > 1 ? ' Candidates' : ' Candidate'}
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Probation Hire</span>
                <p>
                  {referrerPayment?.probationHire || 0} {referrerPayment?.probationHire > 1 ? ' Candidates' : ' Candidate'}
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Company Employee</span>
                <p>
                  {referrerPayment?.employee || 0} {referrerPayment?.employee > 1 ? ' Candidates' : ' Candidate'}
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Unpaid Refers</span>
                <p>
                  {referrerPayment?.employee - referrerPayment?.employeePaid}
                  {referrerPayment?.employee - referrerPayment?.employeePaid > 1 ? ' Refers' : ' Refer'}
                </p>
              </li>
            </ul>
            <h2 className="m-t-xxl m-b-sm p-t-md">Payment Information</h2>
            <ul>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Total Reward</span>
                <p>
                  <NumberFormat value={paymentInfo?.income} displayType={'text'} thousandSeparator={true} /> VND
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Paid Reward</span>
                <p>
                  <NumberFormat value={paymentInfo?.withdrawFee} displayType={'text'} thousandSeparator={true} /> VND
                </p>
              </li>
              <li className="d-inline-block min-width-150 ver-top p-r-sm m-r-sm">
                <span className="fs-12 bold text-black-light">Unpaid Reward</span>
                <p>
                  <NumberFormat value={paymentInfo?.tnmDebt} displayType={'text'} thousandSeparator={true} /> VND
                </p>
              </li>
            </ul>
            <h2 className="m-t-xxl m-b-sm">Candidate</h2>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group width-300 m-b-none m-r-sm d-inline-block">
                  <input
                    placeholder="Search Name or Email"
                    className="form-control have-icon small"
                    value={searchData.keyword}
                    onChange={onKeyword}
                  />
                  <span className="form-control-icon transform pointer">
                    <span className="mat-icon-outline fs-20">search</span>
                  </span>
                </div>
                <div className="dropdown m-r-sm d-inline-block">
                  <div className="form-group small">
                    <Select
                      suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                      allowClear={true}
                      value={searchData.companyId}
                      onChange={onSelectedCompany}
                      onPopupScroll={loadMoreCompany}
                      placeholder="All company"
                    >
                      {companys.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="m-r-sm d-inline-block">
                  <div className="form-group small custom-picker">
                    <DatePicker
                      key="p-period"
                      suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                      format="MMM-yyyy"
                      picker="month"
                      defaultValue={probationDate}
                      placeholder="Probation Month"
                      onChange={onChangeProbationDate}
                    ></DatePicker>
                  </div>
                </div>
                <div className="m-r-sm d-inline-block">
                  <div className="form-group small custom-picker">
                    <DatePicker
                      key="p-period"
                      suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                      format="MMM-yyyy"
                      picker="month"
                      defaultValue={employeeDate}
                      placeholder="Employee Month"
                      onChange={onChangeEmployeeDate}
                    ></DatePicker>
                  </div>
                </div>
                <div className="dropdown m-r-sm d-inline-block">
                  <div className="form-group small">
                    <Select
                      suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                      allowClear={true}
                      value={searchData.status}
                      onChange={onSelectedStatus}
                      placeholder="Payment status"
                    >
                      {paymentStatus.map(item => (
                        <Option key={item.code} value={item.code}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="col-md-12 m-t-lg custom-table">
                <Table
                  columns={columnDefs}
                  dataSource={list}
                  onChange={loadList}
                  pagination={{
                    hideOnSinglePage: true,
                    total: searchData.totalItems,
                    pageSize: searchData.pageSize,
                    current: searchData.pageNumber,
                    showSizeChanger: false,
                  }}
                ></Table>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
});

export default ReferrerPaymentModal;
