import { BaseService } from '@helpers/https';
import { SearchPaymentPipeline } from '@models/base';

export class PipelinePaymentService extends BaseService {
  hostUrl = this.host + '/payment/candidate-pipelines';
  private static _instance: PipelinePaymentService;
  static get instance() {
    if (!this._instance) this._instance = new PipelinePaymentService();
    return this._instance;
  }

  getByUser(userId: string, searchData: SearchPaymentPipeline) {
    const url = `by-user/${userId}`;
    return this.get<any>(url, { params: searchData });
  }

  getByCompany(companyId: number, searchData: SearchPaymentPipeline) {
    const url = `by-company/${companyId}`;
    return this.get<any>(url, { params: searchData });
  }

  newConfirm(body: { userId: string; candidatePipeLineId: number }) {
    const url = `new-confirmed`;
    return this.post(url, body);
  }

  paidConfirm(body: { userId: string; candidatePipeLineId: number; isOverBalance: boolean }) {
    const url = `paid-confirmed`;
    return this.post(url, body);
  }

  companyNewConfirm(body: { companyId: number; candidatePipeLineId: number }) {
    const url = `new-confirmed-to-company`;
    return this.post(url, body);
  }

  companyPaidConfirm(body: { companyId: number; candidatePipeLineId: number }) {
    const url = `paid-confirmed-to-company`;
    return this.post(url, body);
  }
}
