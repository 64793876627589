import { EncryptResponed } from '@models/base';
import { AluhaMenu } from '@models/menu';
import * as CryptoJS from 'crypto-js';
import { CommonHelper } from './commonHelper';
import { CookieHelper } from './cookieHelper';
import { CookieStorage } from './cookieStorage';
import { LocalStorage } from './localStorage';
import { SessionStorage } from './sessionStorage';
export class SecurityHelper {
  static secrectKey = 'akes!@#616b6573214023@#$#%24023242325324024402340@#$#%2232473646653@#$#%244465';
  static authenKey = 'admin.tnm.website';
  static hashData(data: string) {
    const ciphertext = CryptoJS.MD5(data).toString();
    return ciphertext;
  }

  static encrypt(data: any, isOnlyValue = false, secrectKey?: string) {
    if (!data) {
      return '';
    }
    if (typeof data === 'object' && data.constructor === Object) {
      if (isOnlyValue) {
        const keys = Object.keys(data);
        keys.forEach(key => {
          const ciphertext = CryptoJS.AES.encrypt(data[key], 'kenadf3f#$@#4adsa$%45gsdg#$%#$').toString();
          data[key] = ciphertext;
        });
        return data;
      } else {
        data = JSON.stringify(data);
        const ciphertext = CryptoJS.AES.encrypt(data, secrectKey || this.secrectKey).toString();
        return ciphertext;
      }
    } else {
      const ciphertext = CryptoJS.AES.encrypt(data, secrectKey || this.secrectKey);
      return ciphertext.toString();
    }
  }

  static encryptUrl(data: any, isOnlyValue = false) {
    if (!data) {
      return '';
    }
    if (typeof data === 'object' && data.constructor === Object) {
      if (isOnlyValue) {
        const keys = Object.keys(data);
        keys.forEach(key => {
          const ciphertext = this.enUrl(data[key], 'kenadf3f#$@#4adsa$%45gsdg#$%#$');
          data[key] = ciphertext;
        });
        return data;
      } else {
        data = JSON.stringify(data);
        const eHex = this.enUrl(data);
        return eHex;
      }
    } else {
      const ciphertext = this.enUrl(data);
      return ciphertext.toString();
    }
  }

  static enUrl(data: string, key?: string) {
    // const iv = CryptoJS.enc.Utf8.parse(data);
    const ciphertext = CryptoJS.AES.encrypt(data, key || this.secrectKey).toString();
    const e64 = CryptoJS.enc.Base64.parse(ciphertext);
    const eHex = e64.toString(CryptoJS.enc.Hex);
    return eHex;
  }

  static decrypt(data: string, toObject = false, secrectKey?: string) {
    if (!data) {
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(data, secrectKey || this.secrectKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    if (toObject && plaintext) {
      return JSON.parse(plaintext);
    }
    return plaintext;
  }

  static decrytDataGraph(data?: EncryptResponed) {
    if (data && data.hashData) {
      return this.decrypt(data.hashData, true, this.token);
    }
    return undefined;
  }

  static createCookieAccess(data: any) {
    if (data) {
      LocalStorage.createStore('access.page', data);
    }
  }

  static getCookieAccess() {
    return LocalStorage.getStore('access.page');
  }

  static decryptUrl(data: string, toObject = false) {
    if (!data) {
      return null;
    }
    const reb64 = CryptoJS.enc.Hex.parse(data);
    const bytes = reb64.toString(CryptoJS.enc.Base64);
    const decrypt = CryptoJS.AES.decrypt(bytes, this.secrectKey);
    const plaintext = decrypt.toString(CryptoJS.enc.Utf8);
    if (toObject && plaintext) {
      return JSON.parse(plaintext);
    }
    return plaintext;
  }

  static createStore(key: string, data: any) {
    if (data !== null && data !== undefined) {
      LocalStorage.createStore(key, data);
    }
  }

  static getStore(key: string) {
    return LocalStorage.getStore(key);
  }

  static getCookieToken(key = this.authenKey) {
    return CookieStorage.getStore(key);
  }

  static createCookieToken(key: string, token: any) {
    if (!key) {
      key = this.authenKey;
    }
    if (token) {
      CookieHelper.createStore(key, token);
    }
  }

  static removeStore(key: string) {
    return LocalStorage.removeStore(key);
  }

  static createStoreAuthen(data: any) {
    this.createStore(this.authenKey, data);
    if (data.token) {
      CookieHelper.createStore(this.authenKey, data.token);
    }
  }
  static getStoreAuthen() {
    return this.getStore(this.authenKey);
  }

  static get token() {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken && authToken.token) {
      return authToken.token;
    }
    return undefined;
  }

  static destroyAuthen() {
    this.removeStore(this.authenKey);
    this.removeStore('roles_job');
    // Constants.returnUrl = '/';
    // CookieHelper.removeStore(this.authenKey);
  }

  static get isLogin() {
    const user = SecurityHelper.getStoreAuthen();
    if (user && user.user && user.token) {
      return true;
    }
    return false;
  }

  static get isLite() {
    const session = SecurityHelper.getStoreAuthen();
    if (session && session.user && session.token && session.user.isLite) {
      return true;
    }
    return false;
  }

  static get getRoles() {
    const { user } = SecurityHelper.getStoreAuthen();
    if (user && user.roles) {
      let roles;
      if (typeof user.roles === 'string') {
        roles = JSON.parse(user.roles);
      } else {
        roles = user.roles;
      }
      return { roles, xtype: user.xtype };
    }
    return undefined;
  }

  static get roles() {
    const { user } = SecurityHelper.getStoreAuthen();
    if (user && user.roles) {
      let roles;
      if (typeof user.roles === 'string') {
        roles = JSON.parse(user.roles);
      } else {
        roles = user.roles;
      }
      return roles;
    }
    return undefined;
  }

  static checkPermission(controller: string, action: string, atype?: string) {
    const { user, xtype } = SecurityHelper.getStoreAuthen();
    if (user && user.roles) {
      let roles;
      if (typeof user.roles === 'string') {
        roles = JSON.parse(user.roles);
      } else {
        roles = user.roles;
      }
      const xT = atype || xtype || user.xtype;
      const permissionFound = roles.find(
        (x: any) =>
          x.controller.toUpperCase() === controller.toUpperCase() &&
          x.action.toUpperCase() === action.toUpperCase() &&
          x.type === xT,
      );
      if (permissionFound) {
        return true;
      }
    }
    return false;
  }
  static checkPermissionByJob2(jobId: number) {
    const data = SecurityHelper.getStore('roles_job');
    if (data && data.roles) {
      if (data.jobId === jobId) {
        return false;
      }
      return true;
    }
    return true;
  }

  static checkPermissionUrl(url: string) {
    const session = SecurityHelper.getRoles;
    if (session) {
      const allRoles = session.roles;
      const permissionFound = allRoles.find((x: any) => x.url && x.url.toUpperCase() === url.toUpperCase());
      if (permissionFound) {
        return true;
      }
    }
    return false;
  }

  static getStateUrl(path: string, url: string) {
    const index = url.indexOf(path);
    if (index >= 0) {
      return url;
    }
    return undefined;
  }

  static get isAdblock() {
    return this.getStore('adblock');
  }

  static createSessionStore(key: string, data: any) {
    if (data !== null && data !== undefined) {
      SessionStorage.createStore(key, data);
      if (data.token) {
        CookieHelper.createStore(key, data.token);
      }
    }
  }

  static getSessionStore(key: string) {
    return SessionStorage.getStore(key);
  }

  static removeSessionStore(key: string) {
    if (key === this.authenKey) {
      CookieHelper.removeStore(key);
    }
    return SessionStorage.removeStore(key);
  }

  static checkMenuPermission(menuList: AluhaMenu[]): AluhaMenu[] {
    if (menuList) {
      const session = this.getRoles;
      if (session) {
        const roles = session.roles;
        const menus = [].concat(menuList as any);
        const menuRoles = roles.filter((x: any) => x.url);
        const finalList: AluhaMenu[] = [];
        menus.forEach((item: any) => {
          const newItem: AluhaMenu = Object.assign({}, item);
          if (item.childrens && item.childrens.length) {
            const childrens = CommonHelper.filterMenu(item, menuRoles);
            if (childrens && childrens.length) {
              newItem.childrens = childrens;
              finalList.push(newItem);
            }
          } else {
            if (newItem.checkPermission) {
              const check = menuRoles.some((x: any) => x.url && (x.url === newItem.url || x.url === newItem.customUrl));
              if (check) {
                finalList.push(newItem);
              }
            } else if (newItem && newItem.subPermission && newItem.subPermission.length) {
              const check = menuRoles.some((x: AluhaMenu) => x.url && newItem.subPermission?.find(p => p === x.url));
              if (check) {
                finalList.push(newItem);
              }
            } else {
              finalList.push(newItem);
            }
          }
        });
        return finalList;
      }
    }
    return [];
  }
}
