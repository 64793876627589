import { BaseService } from '@helpers/https';

export class TermService extends BaseService {
  hostUrl = this.host + '/auth/term-condition';
  private static _instance: TermService;
  static get instance() {
    if (!this._instance) this._instance = new TermService();
    return this._instance;
  }

  getPrevious() {
    const url = 'detail';
    return this.get(url);
  }

  updateTerm(body: any) {
    return this.add(body);
  }
}
