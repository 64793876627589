import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SecurityHelper } from '@helpers/securityHelper';
import { UserModel } from '@models/users/user';
import { Toastr } from '@helpers/toastr';
import { Constants } from '@config/constant';
import { AuthenService } from '@services/authen.service';
export interface IAuthContext {
  isAuthenticated: boolean;
  checkAuth: () => void;
  login: (values: UserModel) => void;
  logout: () => void;
}

export const AuthContext = React.createContext<IAuthContext>({} as any);

export default function Auth({ children }: { children: any }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  const authenService: AuthenService = AuthenService.instance;

  const checkAuth = async () => {
    const result = await authenService.getCurrentExpiredTime();
    if (result <= 0) {
      setIsAuthenticated(false);
      SecurityHelper.destroyAuthen();
      navigate('/login');
    } else {
      setIsAuthenticated(true);
    }
    return result;
  };
  useEffect(() => {
    console.log('Run', new Date().toISOString());
    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const login = (values: UserModel) => {
    SecurityHelper.destroyAuthen();
    authenService.login(values).subscribe(result => {
      if (result && result.id > 0) {
        const data = result.data;
        SecurityHelper.createStoreAuthen(data);
        if (data.user) {
          const urlBefore = sessionStorage.getItem('url_before');
          if (urlBefore) {
            sessionStorage.setItem('url_before', '');
            navigate(urlBefore);
          } else {
            navigate('/');
          }
        }
      } else if (result && result.id && result.id === -3) {
        Toastr.error(result.message);
      } else {
        Toastr.error(result.message);
      }
    });
  };

  const logout = () => {
    SecurityHelper.destroyAuthen();
    Constants.routerPrevious = undefined;
    sessionStorage.setItem('url_before', '');
    navigate('/login');
    authenService.logout();
  };

  return <AuthContext.Provider value={{ isAuthenticated, checkAuth, login, logout }}>{children}</AuthContext.Provider>;
}

Auth.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.element]),
};
