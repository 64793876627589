/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { TNMRouter } from './router';
import GlobalLoader from '@components/Loader';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet titleTemplate="%s - TNM" defaultTitle="THENEXTMOVE-ADMIN" htmlAttributes={{ lang: i18n.language }}>
        <meta name="description" content="TheNexMove application" />
      </Helmet>
      <GlobalLoader>
        <TNMRouter></TNMRouter>
      </GlobalLoader>
    </BrowserRouter>
  );
}
