import { CSSProperties, FunctionComponent, useEffect, useState } from 'react';
import defaultImage from '../../../assets/images/no-image.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface ImageProps {
  url: string;
  defaultImage?: string;
  type: 'CDN' | 'LOCAL';
  alt?: string;
  style?: CSSProperties | undefined;
  className?: string;
}

const XImage: FunctionComponent<ImageProps> = props => {
  const cdnServer = process.env.REACT_APP_CDN_URL + '/cdn/system/file/';
  let defaultUrl = defaultImage;
  const [imgUrl, setImageUrl] = useState(props.url);
  const [display, setDisplay] = useState(props.url);
  useEffect(() => {
    if (props.defaultImage) defaultUrl = props.defaultImage;
    if (!props.url) props.url = defaultUrl;
    setDisplay('none');
    checkUrl(props.url);
  }, [props.url]);

  function updateUrl() {
    setImageUrl(defaultUrl);
  }

  function checkUrl(value: string) {
    if (value !== undefined && checkIsBase64(value)) {
      setImageUrl(value);
    } else if (value && !checkIsBase64(value) && props.type === 'CDN') {
      setImageUrl(cdnServer + value);
    } else if (value === undefined) {
      setImageUrl(defaultUrl);
    }
    setDisplay('initial');
  }

  function checkIsBase64(value: string) {
    try {
      if (value) {
        const index = value.indexOf('data:image');
        return index > -1;
      }
      return false;
    } catch (err) {
      return false;
    }
  }

  return (
    <LazyLoadImage
      src={imgUrl}
      style={{ display: display, ...props.style }}
      onError={updateUrl}
      crossOrigin="anonymous"
      className={props.className}
      alt={props.alt}
    />
  );
};

export default XImage;
