import { BaseService } from '@helpers/https';

export class CompanyService extends BaseService {
  hostUrl = this.host + '/core/admin-companies';
  private static _instance: CompanyService;
  static get instance() {
    if (!this._instance) this._instance = new CompanyService();
    return this._instance;
  }

  getInfo(companyId: number) {
    const url = `/overview/${companyId}`;
    return this.get<any>(url);
  }

  approveCompany() {
    const url = '/archived';
    return this.update(url);
  }
}
