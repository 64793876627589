import { Loading } from '@helpers/loading';
import { Toastr } from '@helpers/toastr';
import { Navigate } from 'react-router-dom';
import { SecurityHelper } from '../securityHelper';

export const handleError = (err: any) => {
  Loading.hide();
  if (err.response) {
    if (err.response.status === 401) {
      if (SecurityHelper.isLogin) {
        <Navigate to="/login" replace />;
      }
      SecurityHelper.destroyAuthen();
    } else {
      const status = err.response.status;
      const data = err.response.data;
      const errorText = err.response.statusText;
      if (data && data.error) {
        Toastr.error(data.error, `[${status}]`);
      } else if (typeof errorText === 'string') {
        Toastr.error(errorText);
      }
    }
  }
  return err;
};
