/* eslint-disable no-template-curly-in-string */
/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import FontFaceObserver from 'fontfaceobserver';
import '@helpers/prototype';
import '@helpers/observable';

// Use consistent styling
import './assets/css/customize.css';
import './index.less';
import './index.css';
import './tinymce';
import './assets/scss/main.scss';

import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
// Initialize languages
import './locales/i18n';
import { App } from './app';
import { configureAppStore } from '@store/configureStore';
import { ThemeProvider } from '@styles/theme/ThemeProvider';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import { Constants } from '@config/constant';
import { graphSqlClient } from '@helpers/https/graphsql';

// Observe loading of Inter (to remove 'Inter', remove the <link> tag in
// the index.html file and this observer)
const openSansObserver = new FontFaceObserver('Inter', {});
const module: any = {};
// When Inter is loaded, add a font-family using Inter to the body
openSansObserver.load().then(() => {
  document.body.classList.add('fontLoaded');
});

const store = configureAppStore();
Constants.store = store;
const MOUNT_NODE = document.getElementById('root') as HTMLElement;
const validateMessages = {
  required: '${label} is Required!',
  number: {
    len: '${label} must be exactly 10 characters',
    // min: ValidateMessage,
    // max: ValidateMessage,
    // range: ValidateMessage,
  },
  string: {
    len: '${label} must be exactly 10 characters',
    // min: ValidateMessage,
    // max: ValidateMessage,
    // range: ValidateMessage,
  },
};
ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider form={{ validateMessages }}>
      <ApolloProvider client={graphSqlClient}>
        <ThemeProvider>
          <HelmetProvider>
            {/* <React.StrictMode> */}
            <App />
            {/* </React.StrictMode> */}
          </HelmetProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ConfigProvider>
  </Provider>,
  MOUNT_NODE,
);

// Hot reloadable translation json files
if (module.hot) {
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
