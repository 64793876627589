import { createSelector } from 'reselect';

/**
 * Direct selector to the brand state domain
 */

const selectBrandDomain = (state: any) => {
  return state.counter;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by Brand
 */

const getStatus = () =>
  createSelector(selectBrandDomain, counter => {
    return counter.onStatus;
  });

export { getStatus };
