import * as CryptoJS from 'crypto-js';

export class SessionStorage {
  private static secrectKey = 'akes!@#616b6573214023@#$#%24023242325324024402340@#$#%2232473646653@#$#%244465';

  private static encrypt(data: string) {
    if (!data) {
      return '';
    }
    const ciphertext = CryptoJS.AES.encrypt(data, this.secrectKey);
    return ciphertext.toString();
  }

  private static decrypt(data: string) {
    if (!data) {
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(data, this.secrectKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }

  static createStore(key: string, data: any) {
    if (data) {
      const hashData = this.encrypt(JSON.stringify(data));
      sessionStorage.setItem(key, hashData);
    }
  }

  static getStore(key: string) {
    let hashData = sessionStorage.getItem(key);
    if (hashData) {
      hashData = hashData.trym('"');
      const plainText = this.decrypt(hashData);
      if (plainText) {
        const result = JSON.parse(plainText);
        return result;
      }
    }
    return undefined;
  }
  static removeStore(key: string) {
    sessionStorage.removeItem(key);
  }
}
