import { Loading } from '@helpers/loading';
import { Toastr } from '@helpers/toastr';
import { Permission } from '@models/base/permission';
import { UserModel } from '@models/users/user';
import { createSelector } from '@reduxjs/toolkit';
import { UserService } from '@services/user.service';
import { put, select, takeLatest } from 'redux-saga/effects';

import { userCreateActions as actions } from './slice';
import { userActions } from '../slice/index';
import { ApiResponse } from '@models/base';
import { getMessage, graphSqlClient } from '@helpers/https';
import { CREATE_USER, UPDATE_USER } from '../slice/query';

const userService = UserService.instance;

export interface UserCreateState {
  roles?: Array<Permission>;
  isVisible: boolean;
  user?: UserModel;
  reload?: Date;
}

/* 
  If you want to use 'ContainerState' keyword everywhere in your feature folder, 
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = UserCreateState;

export const initialState: UserCreateState = {
  roles: [],
  isVisible: false,
  reload: new Date(),
};

const selectDomain = (state: any) => state.userCreate || initialState;
export const selectRoles = createSelector([selectDomain], state => {
  if (state.roles) {
    const results = state.roles.map((item: Permission) => {
      const newItem = { ...item };
      if (item.actions) {
        newItem.actions = item.actions.map(ac => {
          return { ...ac };
        });
      }
      return newItem;
    });
    return results;
  }
  return state.roles;
});
export const selectVisible = createSelector([selectDomain], (state: UserCreateState) => state.isVisible);
export const selectUserInput = createSelector([selectDomain], (state: any) => state.user);

function* getRole(): any {
  const roles = yield userService.getRole().execute();
  yield put(actions.roleLoaded(roles));
}
function* createOrUpdate(): any {
  const user: UserModel = yield select(selectUserInput);
  if (!user.roles!.length) {
    Toastr.error('The role is empty');
    return;
  }
  Loading.show();
  let result: ApiResponse;
  const input: any = { ...user };
  delete input.confirm;
  if (user._id) {
    // result = yield userService.update(user).execute();
    result = yield graphSqlClient.mutate({
      mutation: UPDATE_USER,
      variables: { input: { fullName: user.fullName, roles: user.roles, _id: user._id } },
      context: { clientName: 'authenLink' },
    });
  } else {
    // result = yield userService.create(user).execute();
    result = yield graphSqlClient.mutate({
      mutation: CREATE_USER,
      variables: { input },
      context: { clientName: 'authenLink' },
    });
  }
  const msg = getMessage(result);
  Toastr.messageCome(msg);
  if (msg.id > 0) {
    yield put(userActions.reload());
    // yield graphSqlClient.refetchQueries({
    //   include: ['user'],
    // });
    yield put(actions.openCreate(false));
  }
  Loading.hide();
}

export function* userCreateSaga() {
  yield takeLatest(actions.loadRole.type, getRole);
  yield takeLatest(actions.createOrUpdate.type, createOrUpdate);
}
