import { Message } from '@models/base/message';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/lib/notification';
export class Toastr {
  static message(code: number, messsage: string) {
    if (code > 0) {
      Toastr.success(messsage);
    } else {
      Toastr.error(messsage);
    }
  }
  static messageCome(msg: Message) {
    if (msg.id > 0) {
      Toastr.success(msg.message);
    } else {
      Toastr.error(msg.message);
    }
  }
  static success(msg: string, title = 'Success', position: NotificationPlacement = 'topRight') {
    if (msg && typeof msg === 'string') {
      notification['success']({
        message: title,
        description: msg,
        placement: position,
      });
    }
  }
  static warning(msg: string, title = 'Warning', position: NotificationPlacement = 'topRight') {
    if (msg && typeof msg === 'string') {
      notification['success']({
        message: title,
        description: msg,
        placement: position,
      });
    }
  }
  static info(msg: string, title = 'Infomation', position: NotificationPlacement = 'topRight') {
    if (msg && typeof msg === 'string') {
      notification['info']({
        message: title,
        description: msg,
        placement: position,
      });
    }
  }
  static error(msg: string, title = 'Error', position: NotificationPlacement = 'topRight') {
    if (msg && typeof msg === 'string') {
      notification['error']({
        message: title,
        description: msg,
        placement: position,
      });
    }
  }
}
