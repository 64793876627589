import { PubSubEvent } from '@helpers/services/pub-subevent';
import { PubSubService } from '@helpers/services/pubsub.service';
import WebHelper from '@helpers/webHelper';
import React from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import BreadCrumbLink from './breadcrumb-link';

interface HeaderProps {}

const Header: FunctionComponent<HeaderProps> = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<any[]>([]);

  useEffect(() => {
    const subEvent = PubSubService.instance.$sub(PubSubEvent.EVENTS.ON_UPDATE_PAGE_TITLE).subscribe(() => {
      getBreadCrumbs();
    });
    return () => {
      WebHelper.unSubscribe(subEvent);
    };
  }, []);

  const getBreadCrumbs = () => {
    const title = document.title;
    const titles = title.split('-');
    if (titles && titles.length > 1) {
      const ob: any = { name: titles[0], status: 'active' };
      setBreadcrumbs([ob]);
    } else {
      const ob: any = { name: title, status: 'active' };
      setBreadcrumbs([ob]);
    }
  };
  return (
    <div className="header-page">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-7">
            <BreadCrumbLink breadcrumbs={breadcrumbs} />
          </div>
          <div className="col-md-5 text-end">
            <span className="icon-action dropdown-toggle">
              <span className="mat-icon-outline pointer">info</span>
            </span>
            <div className="m-r-sm d-inline-block vertical-mid">{/* <app-notification></app-notification> */}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
