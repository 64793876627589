import * as fns from 'date-fns';
import { format } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { Locale } from 'date-fns';
export class DateHelper {
  static currentDate = new Date();

  static formatDateFromServer(value: string) {
    if (value) {
      value = this.removeISO(value);
      const date = new Date(value);
      return date;
    }
    return new Date();
  }

  static format(value: Date, format = 'DD-MM-YYYY h:mm:ss') {
    if (value) {
      value = this.removeISO(value);
      const date = fns.format(value, format);
      return date;
    }
    return value;
  }
  static toDateNotTime(value: Date, format = 'YYYY-MM-DD'): Date | undefined {
    if (value) {
      const date222 = new Date(fns.format(value, format));
      return date222;
    }
    return undefined;
  }
  static formatServer(value?: Date, format = 'YYYY-MM-DD h:mm:ss'): any {
    if (value) {
      const date = fns.format(value, format);
      return date;
    }
    return value;
  }

  static formatServerNotTime(value: any, format = 'YYYY-MM-DD') {
    if (value) {
      const date = fns.format(value, format);
      return date;
    }
    return value;
  }

  static getTimeBetweenDate(date: any) {
    if (!date) {
      return date;
    }
    date = this.removeISO(date);
    const currentTime = new Date().getTime();
    const expiredTime = new Date(date).getTime();
    return currentTime - expiredTime;
  }

  static getDistanceBetweenDate(date: any) {
    if (!date) {
      return date;
    }
    date = this.removeISO(date);
    date = new Date(date);
    const result = fns.differenceInDays(date, new Date());
    return result;
  }

  static formatDate(date: any) {
    const d = new Date(date);
    const dateFormat =
      d.getFullYear() +
      '/' +
      ('00' + (d.getMonth() + 1)).slice(-2) +
      '/' +
      ('00' + d.getDate()).slice(-2) +
      ' ' +
      ('00' + d.getHours()).slice(-2) +
      ':' +
      ('00' + d.getMinutes()).slice(-2) +
      ':' +
      ('00' + d.getSeconds()).slice(-2);
    return dateFormat;
  }

  static removeISO(date: any) {
    if (date && typeof date === 'string') {
      // return date.replace(/([A-Za-z])/gi, ' ');
      return date;
    }
    return date;
  }

  static convertDateStringtoIso(value: any) {
    if (!value) {
      return value;
    }
    const str = value.split(/[\s,-/]+/);
    if (str && str.length) {
      let year = 0;
      let month = 0;
      let day = 0;
      str.forEach((val: any) => {
        // eslint-disable-next-line radix
        if (val.length >= 4 && parseInt(val) > 1950) {
          year = val;
          // eslint-disable-next-line radix
        } else if (val.length <= 2 && parseInt(val) <= 12) {
          month = val;
        } else {
          day = val;
        }
      });
      const date = new Date(year, month, day);
      return date;
    }
  }

  static getFirstTimeOfDateLocaltion(value: any) {
    return new Date(this.formatServerNotTime(value)).setHours(0, 0, 0, 0);
  }

  static getLastTimeOfDateLocaltion(value: any) {
    return new Date(this.formatServerNotTime(value)).setHours(23, 59, 59, 59);
  }

  static formatIsoString(value: any) {
    return new Date(value).toISOString();
  }

  static formatByLocale(value: string, formatString: string, code: string) {
    const result = format(new Date(value), formatString, {
      locale: getDateFnsLocale('enUs'),
    });
    return result;
  }
}
export function getDateFnsLocale(locale: 'enUs' | 'vi'): Locale {
  const local = (Locales as any)[locale];
  return local;
}
