import { MenuHelper } from '@config/menu';
import { SecurityHelper } from '@helpers/securityHelper';
import { Navigate, useLocation } from 'react-router-dom';

const menus = MenuHelper.menusListTNM;

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  //   const { isAuthenticated, loading } = useSelector((state: any) => state.auth);
  const isAuthenticated = SecurityHelper.isLogin;
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  // Permission checking...
  const path = location.pathname;
  const menu = menus.find(x => x.url === path);
  if (menu && menu.checkPermission) {
    const checker = SecurityHelper.checkPermissionUrl(path);
    if (!checker) {
      return <Navigate to="/not-permission" state={{ from: location }} />;
    }
  }

  return children;
};

export default PrivateRoute;
