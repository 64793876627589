import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from '@utils/redux-injectors';
import { UserState } from './types';
import { userFromSaga } from './saga';
import { PaginatedList, SearchModel } from '@models/base';
import { UserModel } from '@models/users/user';

export const initialState: UserState = {
  data: undefined,
  searchData: new SearchModel(true),
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUser(state, action: PayloadAction<any>) {
      state.data = undefined;
      state.searchData = action.payload || state.searchData;
    },
    userLoaded(state, action: PayloadAction<PaginatedList<UserModel> | null>) {
      if (action.payload) {
        state.data = action.payload;
      }
    },
    resetPassword(state, action: PayloadAction<UserModel>) {
      state.userData = action.payload;
    },
    deleteUser(state, action: PayloadAction<UserModel>) {
      state.userData = action.payload;
    },
    reload(state) {
      state.reload = new Date();
    },
  },
});

export const { actions: userActions, reducer } = slice;

export const useUserSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userFromSaga });
  return { actions: slice.actions };
};
