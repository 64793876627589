import { ApiResponse } from '@models/base';
import { Observable, Observer } from 'rxjs';
import AxisSystem from './axios';

export abstract class BaseService {
  abstract hostUrl: string;
  host = process.env.REACT_APP_GATE_WAY;
  constructor(host?: string) {
    if (host) {
      this.host = host;
    }
  }

  getAll<T>(params?: any): Observable<T> {
    return new Observable<T>((observer: Observer<T>) => {
      const url = this.hostUrl;
      // this.showLoading(params);
      AxisSystem.getSync(url, { params }).subscribe({
        next: (data: any) => {
          observer.next(data);
        },
        error: (error: any) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }
  getDropdown<T>(params?: any) {
    const url = this.hostUrl + '/get-dropdown';
    return new Observable<T>((observer: Observer<T>) => {
      AxisSystem.getSync(url, params).subscribe({
        next: (data: any) => {
          observer.next(data);
        },
        error: (error: any) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }

  getById(id: any, params?: { noLoading: boolean }) {
    const url = `${this.hostUrl}/get-by-id/${id}`;
    return new Observable((observer: Observer<any>) => {
      AxisSystem.getSync(url).subscribe({
        next: (data: any) => {
          observer.next(data);
        },
        error: (error: any) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }

  add(data: any) {
    return new Observable((observer: Observer<any>) => {
      AxisSystem.postSync(this.hostUrl, data).subscribe({
        next: (resp: any) => {
          observer.next(resp);
        },
        error: (error: ApiResponse) => {
          observer.error(error);
        },
        complete: () => {
          //
        },
      });
    });
  }

  update(data: any) {
    return this.put('', data, undefined);
  }

  put(url: string, data?: any, params?: any) {
    let xurl = this.hostUrl;
    if (url) {
      xurl += '/' + url;
    }
    return new Observable((observer: Observer<any>) => {
      AxisSystem.putSync(xurl, data, params).subscribe({
        next: (resp: any) => {
          observer.next(resp);
        },
        error: (error: ApiResponse) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }

  deleteById(id: string, params?: any) {
    return this.delete(``, { id, ...params });
  }
  deleteByIdParam(id: any, params?: any) {
    const url = id;
    return this.delete(url, params);
  }

  deleteByIntergerId(id: number, params?: any) {
    return this.delete(``, { id, ...params });
  }

  delete(apiUrl: string, params?: any) {
    const requestUrl = new URL(`${this.hostUrl}/${apiUrl}`);

    if (params) {
      Object.keys(params).forEach(key => {
        if (key !== 'noLoading') {
          requestUrl.searchParams.append(key, params[key]);
        }
      });
    }
    return new Observable((observer: Observer<any>) => {
      AxisSystem.deleteSync(requestUrl.href).subscribe({
        next: (resp: any) => {
          observer.next(resp);
        },
        error: (error: ApiResponse) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }

  // delete(params: any) {
  //   return this.deleteByUrl(``, params);
  // }

  get<T>(apiUrl: string, params?: any): Observable<T> {
    const url = `${this.hostUrl}/${apiUrl}`;
    return this.execGet(url, params);
  }
  getUrl<T>(apiUrl: string, params?: any): Observable<T> {
    return this.execGet(apiUrl, params);
  }

  execGet<T>(apiUrl: string, params?: any): Observable<T> {
    return new Observable((observer: Observer<any>) => {
      AxisSystem.getSync(apiUrl, params).subscribe({
        next: (data: any) => {
          observer.next(data);
        },
        error: (error: any) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }

  post<T>(apiUrl: string, data?: any, params: any = {}) {
    const requestUrl = `${this.hostUrl}/${apiUrl}`;
    return this.execPost<T>(requestUrl, data, params);
  }
  postUrl<T>(apiUrl: string, data?: any, params: any = {}) {
    return this.execPost<T>(apiUrl, data, params);
  }

  private execPost<T>(apiUrl: string, data?: any, params: any = {}) {
    if (params && params.noLoading) {
      delete params.noLoading;
    }
    return new Observable((observer: Observer<T>) => {
      AxisSystem.postSync(apiUrl, data, { params }).subscribe({
        next: (resp: any) => {
          observer.next(resp);
        },
        error: (error: ApiResponse) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });
    });
  }
}
