import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from '@utils/redux-injectors';
import { Permission } from '@models/base/permission';
import { initialState } from './redux-saga';
import { userCreateSaga } from './redux-saga';
import { UserModel } from '@models/users/user';

const slice = createSlice({
  name: 'userCreate',
  initialState,
  reducers: {
    loadRole(state) {
      state.roles = [];
    },
    roleLoaded(state, action: PayloadAction<Permission[]>) {
      state.roles = action.payload;
    },
    openCreate(state, action: PayloadAction<boolean>) {
      state.isVisible = action.payload;
    },
    edit(state, action: PayloadAction<UserModel>) {
      state.user = action.payload;
      state.isVisible = true;
    },
    createOrUpdate(state, action: PayloadAction<UserModel>) {
      state.user = action.payload;
    },
    reload(state, action: PayloadAction<Date>) {
      state.reload = action.payload;
    },
    reset(state) {
      state.user = undefined;
    },
  },
});

export const { actions: userCreateActions, reducer } = slice;

export const useUserCreateSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: userCreateSaga });
  return { createActions: slice.actions };
};
