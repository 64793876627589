import React, { FunctionComponent, useContext, useEffect } from 'react';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '@contexts/Auth';
import { AluhaMenu } from '@models/menu';
import { SecurityHelper } from '@helpers/securityHelper';
import { MenuHelper } from '@config/menu';
const MenuLeft: FunctionComponent = () => {
  const { logout } = useContext(AuthContext);
  const [menus, setMenu] = useState<AluhaMenu[]>([]);
  const version = process.env.version;
  useEffect(() => {
    setMenu(SecurityHelper.checkMenuPermission(MenuHelper.menusListTNM));
  }, []);

  return (
    <div className="aside-left">
      <ul className="menu-left">
        {menus.map(menu => (
          <li key={menu.id}>
            <NavLink to={menu.url || ''} className={({ isActive }) => (isActive ? 'menu-item actived' : 'menu-item')}>
              <span
                className={
                  menu.className ? menu.className + ' mat-icon-outline menu-icon fix-icon' : 'mat-icon-outline menu-icon fix-icon'
                }
              >
                {menu.icon}
              </span>
              <span className="menu-name">{menu.title}</span>
              {/* <span className="menu-name">{t(menu.title || '')}</span> */}
            </NavLink>
          </li>
        ))}

        <li key="menu_logout" className="menu-item fix-end">
          <span className="mat-icon-outline menu-icon">exit_to_app</span>
          <div onClick={logout} className="menu-name">
            Logout
          </div>
        </li>
      </ul>
      <span className="text-version">Version: {version}</span>
    </div>
    // <Sider
    //   collapsible
    //   collapsed={collapsed}
    //   onCollapse={() => setCollapsed(!collapsed)}
    // >
    //   <div className="logo" />
    //   <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
    //     <Menu.Item key="1" icon={<PieChartOutlined />}>
    //       <Link to="/users">Users</Link>
    //     </Menu.Item>
    //     <Menu.Item key="2" icon={<DesktopOutlined />}>
    //       <Link to="/">Dashboard</Link>
    //     </Menu.Item>
    //     <SubMenu key="sub1" icon={<UserOutlined />} title="User">
    //       <Menu.Item key="3">Tom</Menu.Item>
    //       <Menu.Item key="4">Bill</Menu.Item>
    //       <Menu.Item key="5">Alex</Menu.Item>
    //     </SubMenu>
    //     <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
    //       <Menu.Item key="6">Team 1</Menu.Item>
    //       <Menu.Item key="8">Team 2</Menu.Item>
    //     </SubMenu>
    //     <Menu.Item key="9" icon={<FileOutlined />} onClick={logout}>
    //       Logout
    //     </Menu.Item>
    //   </Menu>
    // </Sider>
  );
};

export default MenuLeft;
