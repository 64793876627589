import BlankLayout from '@components/Layout/blank';
import { MainLayout } from '@components/Layout/main';
import { HomePage } from '@pages/HomePage';
import { NotFoundPage } from '@pages/NotFoundPage';
import { Route, Routes } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import LoginPage from '@pages/Login';
import Auth from '@contexts/Auth';
import User from '@pages/Settings/User';
import Profile from '@pages/Profile';
import TermsAndConditions from '@pages/Terms';
import NotPermission from '@pages/NotPermission';
import PaymentPage from '@pages/Payment';
import PaymentLayout from '@components/Layout/payment-layout';
import CompanyPaymentPage from '@pages/Payment/company-payment';
import JobPage from '@pages/Job';
import CompanyPage from '@pages/Company';
import SettingsLayout from '@components/Layout/setting-layout';
import KeySettingPage from '@pages/KeySetting';

/**
 * Top level application router
 *
 * @returns {Component}
 */
export const TNMRouter = () => {
  return (
    <Auth>
      <Routes>
        <Route element={<BlankLayout />}>
          <Route path="/login" element={<LoginPage />}></Route>
          <Route path="/not-permission" element={<NotPermission />}></Route>
        </Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="/admin/dashboard" element={<HomePage />} />
          <Route path="/admin/user" element={<User />} />
          <Route path="/admin/job" element={<JobPage />} />
          <Route path="/admin/company" element={<CompanyPage />} />
          <Route path="/admin/profile" element={<Profile />} />
          <Route element={<SettingsLayout />}>
            <Route path="/admin/terms" element={<TermsAndConditions />} />
            <Route path="/admin/key-setting" element={<KeySettingPage />} />
          </Route>
          <Route element={<PaymentLayout />}>
            <Route path="/admin/payment" element={<PaymentPage />} />
            <Route path="/admin/company-payment" element={<CompanyPaymentPage />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Auth>
  );
};
