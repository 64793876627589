import ButtonIcon from '@components/ButtonIcon';
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import { DeleteOutlined, PlusOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import { AgGridReact } from 'ag-grid-react';
import { Button, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import PageWrapper from '@components/PageWrapper';
import { Input } from 'antd';
import { ColDef, ColumnApi, GridOptions, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { useDispatch, useSelector } from 'react-redux';
import { PaginatedList, SearchUser } from '@models/base';
import { useUserSlice } from './slice';
import { selectUsers } from './slice/selectors';
import CreateUpdateUser from './modals/create';
import { UserModel } from '@models/users/user';
import { useUserCreateSlice } from './modals/slice';
import { debounceTime, Subject } from 'rxjs';
import WebHelper from '@helpers/webHelper';
import { useSearchParams } from 'react-router-dom';
import { UrlHelper } from '@helpers/UrlHelper';

interface UserProps {}
const subject = new Subject<any>();
const User: FunctionComponent<UserProps> = () => {
  const { actions } = useUserSlice();
  const { createActions } = useUserCreateSlice();
  const dispatch = useDispatch();
  const reload = useSelector((state: any) => state.user.reload);
  const userSelects = useSelector<{}, PaginatedList<UserModel>>(selectUsers);
  const [users, setUser] = useState<UserModel[]>();
  const [searchParams] = useSearchParams();
  const columnDefs: ColDef[] = useMemo<ColDef[]>(
    () => [
      { field: 'fullName', headerName: 'Name', resizable: true },
      {
        field: 'email',
        headerName: 'Email',
        sortable: true,
        resizable: true,
      },
      {
        headerName: '',
        cellRenderer: (event: ICellRendererParams) => {
          const title = `Are you sure reset password for the [${event.data.fullName}]`;
          return (
            <div>
              <ButtonIcon tooltip="Reset" title={title} onOk={() => reset(event.data)}>
                <ReloadOutlined className="text-success" />
              </ButtonIcon>
              <ButtonIcon tooltip="Delete" title="Are you sure to delete this task?" onOk={() => deleteUser(event.data)}>
                <DeleteOutlined className="text-red-light" />
              </ButtonIcon>
              <ButtonIcon tooltip="Edit" onClick={() => edit(event.data)}>
                <EditOutlined />
              </ButtonIcon>
            </div>
          );
        },
      },
    ],
    [],
  );
  const [searchData, setSearchData] = useState<SearchUser>(new SearchUser(true));
  const [keyword, setKeyword] = useState<string>('');
  const gridOptions = useMemo<GridOptions>(() => {
    return {
      rowHeight: 45,
      headerHeight: 45,
    };
  }, []);

  // const { data, refetch } = useQuery(GET_USERS, {
  //   variables: searchData,
  //   fetchPolicy: 'cache-and-network',
  //   nextFetchPolicy: 'cache-first',
  // });

  // useEffect(() => {
  //   if (data) {
  //     const datas = SecurityHelper.decrytDataGraph(data);
  //     setUser(datas.results);
  //   }
  // }, [data]);

  useEffect(() => {
    UrlHelper.mappingData(searchParams, searchData);
    // searchData.pageSize = searchData.pageSize + 1;
    const search: any = { ...searchData };
    setSearchData(search);
    dispatch(actions.loadUser(searchData));
    // refetch(searchData);
  }, [reload]);

  useEffect(() => {
    if (userSelects) {
      setUser(userSelects.results);
    }
  }, [userSelects]);

  useEffect(() => {
    const subcribe = subject.pipe(debounceTime(500)).subscribe({
      next: v => dispatch(actions.loadUser(v)),
    });
    return () => {
      WebHelper.unSubscribe(subcribe);
    };
  }, []);

  const onGridReady = (e: GridReadyEvent) => {
    // e.columnApi.resetColumnState();
    // e.columnApi.autoSizeAllColumns();
    setTimeout(() => {
      e.api.sizeColumnsToFit();
      // e.columnApi.autoSizeAllColumns();
      // autoSizeAll(e.columnApi);
    }, 100);
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const autoSizeAll = useCallback((columnApi: ColumnApi) => {
    const allColumnIds: any[] = [];
    if (columnApi && columnApi.getAllColumns()) {
      columnApi.getAllColumns()!.forEach((column: any) => {
        allColumnIds.push(column.getId());
      });
      columnApi.autoSizeColumns(allColumnIds);
    }
  }, []);
  const onSearch = (event: any) => {
    // console.log(event.target.value);
    searchData.keyWord = event.target.value;
    setKeyword(event.target.value);
    subject.next({ ...searchData, ...{ keyword } });
  };

  const reset = (item: UserModel) => {
    dispatch(actions.resetPassword(item));
  };

  const deleteUser = (item: UserModel) => {
    dispatch(actions.deleteUser(item));
  };
  const edit = (item: UserModel) => {
    dispatch(createActions.edit(item));
  };

  return (
    <div>
      <Helmet>
        <title>User</title>
        <meta name="description" />
      </Helmet>
      <PageWrapper>
        <Col>
          <div className="form-group width-300 m-b-sm m-r-sm d-inline-block">
            <Input placeholder="Search user" className="form-control have-icon small" value={keyword} onChange={onSearch} />
            <span className="form-control-icon transform">
              <span className="mat-icon-outline fs-20">search</span>
            </span>
          </div>
          <Button className="btn d-inline-block btn-medium" onClick={() => dispatch(createActions.openCreate(true))}>
            <span className="icon">
              <PlusOutlined />
            </span>
            New User
          </Button>
        </Col>
        <Col md={12} className="m-t-lg custom-table">
          <div className="ag-theme-alpine" style={{ height: 500 }}>
            <AgGridReact
              onGridReady={onGridReady}
              rowData={users}
              // components={this.components}
              gridOptions={gridOptions}
              columnDefs={columnDefs}
            ></AgGridReact>
          </div>
        </Col>
      </PageWrapper>
      <CreateUpdateUser title="Create"></CreateUpdateUser>
    </div>
  );
};

export default User;
