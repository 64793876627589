/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { AluhaMenu } from '@models/index';

export class CommonHelper {
  static isRefreshBrowser = false;
  static getBase64(img: File, callback: (img: string) => void): void {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader?.result!.toString()));
    reader.readAsDataURL(img);
  }

  static transferSearchData(ob: any) {
    const data = Object.assign({}, ob);
    Object.keys(data).forEach(key => {
      const val = data[key];
      if (!val) {
        data[key] = '';
      }
    });

    return data;
  }

  static filterMenu(menu: AluhaMenu, roles: any[]) {
    const finalList: AluhaMenu[] = [];
    menu.childrens?.forEach(item => {
      const newItem = Object.assign({}, item);
      if (newItem.childrens && newItem.childrens.length) {
        const childrens = this.filterMenu(newItem, roles);
        if (childrens && childrens.length) {
          newItem.childrens = childrens;
          finalList.push(newItem);
        }
      } else {
        if (newItem.checkPermission) {
          const check = roles.some(x => x.url && x.url === newItem.url);
          if (check) {
            finalList.push(newItem);
          }
        } else {
          finalList.push(newItem);
        }
      }
    });
    return finalList;
  }

  static changeParam(name: string, value: string) {
    let urlNew;

    if (window.location.search) {
      if (!window.location.search.includes(name + '=')) {
        urlNew = window.location.href + '&' + name + '=' + value;
      } else {
        const param = new URLSearchParams(window.location.search);
        param.set(name, value);
        urlNew = window.location.pathname + '?' + param;
      }
    } else urlNew = window.location.href + '?' + name + '=' + value;

    window.history.replaceState({ path: urlNew }, '', urlNew);
  }
}
