import {
  isMobile,
  isTablet,
  isSmartTV,
  isIOS,
  isChromium,
  isEdge,
  isFirefox,
  isIE,
  isOpera,
  isSafari,
} from 'react-device-detect';
import * as Enumerable from 'linq';
import { SecurityHelper } from './securityHelper';
const ip = require('ip');

export default class WebHelper {
  static getBrower() {
    let device = 'Browser';
    let browser = 'Chrome';
    if (isMobile) device = 'Mobile';
    if (isTablet) device = 'isTablet';
    if (isIOS) device = 'isIOS';
    if (isSmartTV) device = 'isSmartTV';

    if (isChromium) browser = 'Chromium';
    if (isEdge) browser = 'Edge';
    if (isFirefox) browser = 'Firefox';
    if (isIE) browser = 'IE';
    if (isOpera) browser = 'Opera';
    if (isSafari) browser = 'Safari';
    const data = {
      device,
      browser,
      ip: ip.address(),
    };

    return data;
  }
  static unSubscribe(...params: any) {
    for (const iterator of params) {
      if (iterator) {
        iterator.unsubscribe();
      }
    }
  }
  static checkPermission(method: string, isOk = false) {
    if (isOk) return true;
    const { data } = SecurityHelper.getStoreAuthen();
    if (data && data.data && data.data.role) {
      if (method !== '/') method = method.replace(/^\/+/i, '');
      const roles = JSON.parse(data.data.role);
      const enumerableData = Enumerable.from(roles);
      const getActions = enumerableData.where((x: any) => x.Permission && x.Permission.method === method).firstOrDefault();
      if (getActions) return true;
      return false;
    }
    return false;
  }

  static checkActivityPermission(controller: string, method: string, toAccess = false) {
    if (toAccess) return true;
    const { data } = SecurityHelper.getStoreAuthen();
    if (data && data.data && data.data.role) {
      controller = controller.replace(/^\/+/i, '');
      method = method.replace(/^\/+/i, '');
      const roles = JSON.parse(data.data.role);
      const enumerableData = Enumerable.from(roles);

      const getActions = enumerableData
        .where(
          (x: any) =>
            x.Permission &&
            x.Permission.controller &&
            x.Permission.method &&
            x.Permission.controller === controller &&
            x.Permission.method === method,
        )
        .firstOrDefault();
      if (getActions) return true;
      return false;
    }
    return false;
  }
}
