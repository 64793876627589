import { BaseService } from '@helpers/https';

export class CompanyPaymentService extends BaseService {
  hostUrl = this.host + '/payment/company-value-candidate';
  private static _instance: CompanyPaymentService;
  static get instance() {
    if (!this._instance) this._instance = new CompanyPaymentService();
    return this._instance;
  }

  getCompanyValueByCompanyId(companyId: number) {
    const url = `${companyId}`;
    return this.get<any>(url, { noLoading: true });
  }
}
