import { BaseService } from '@helpers/https';

export class WalletService extends BaseService {
  hostUrl = this.host + '/payment/wallet-managements';
  private static _instance: WalletService;
  static get instance() {
    if (!this._instance) this._instance = new WalletService();
    return this._instance;
  }

  getWalletByUser(userId: string) {
    const url = `user/${userId}`;
    return this.get<any>(url, { noLoading: true });
  }
}
