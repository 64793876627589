import { MenuHelper } from '@config/menu';
import { SecurityHelper } from '@helpers/securityHelper';
import { AluhaMenu } from '@models/menu';
import { FunctionComponent, useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import MenuLine from './menu-line';

const PaymentLayout: FunctionComponent = () => {
  const [menus, setMenu] = useState<AluhaMenu[]>([]);
  useEffect(() => {
    setMenu(SecurityHelper.checkMenuPermission(MenuHelper.menusPayment));
  }, []);
  return (
    <div>
      <div className="p-lr-md">
        <MenuLine menus={menus} />
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
};
export default PaymentLayout;
