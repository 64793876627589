import { FunctionComponent, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Constants } from '@config/constant';
import { TermService } from '@services/term.service';
import { Toastr } from '@helpers/toastr';
import { Loading } from '@helpers/loading';
import { Popconfirm } from 'antd';
interface TermsAndConditionsProps {}
class Term {
  type?: string;
  contentVI?: string;
  contentUS?: string;
}

const TermsAndConditions: FunctionComponent<TermsAndConditionsProps> = () => {
  const termService: TermService = TermService.instance;
  const editorConfig: any = Constants.TINY_CONFIG;
  const editorViRef = useRef<any>({});
  const editorEnRef = useRef<any>({});
  const [term, setTerm] = useState<Term>();

  function updateTerm() {
    const newTerm: Term = {
      ...term,
      contentVI: editorViRef.current ? editorViRef.current.getContent() : '',
      contentUS: editorEnRef.current ? editorEnRef.current.getContent() : '',
      type: 'referrer',
    };
    if (!newTerm.contentUS || !newTerm.contentVI) {
      Toastr.error('The content is empty!');
      return;
    }
    Loading.show();
    termService.updateTerm(newTerm).subscribe(res => {
      if (res && res.id >= 0) {
        Toastr.success('Success!');
      }
      Loading.hide();
    });
  }

  function passPrevious() {
    Loading.show();
    termService.getPrevious().subscribe((res: any) => {
      if (res) {
        setTerm(res);
      }
      Loading.hide();
    });
  }

  return (
    <div className="wrapper-content p-sm">
      <div className="row">
        <div className="col-6">
          <h2>Terms and Conditions</h2>
        </div>
        <div className="col-6 text-end">
          <button className="btn m-r-xs" onClick={passPrevious}>
            Paste From Previous
          </button>
          <Popconfirm title="Are you sure to update?" onConfirm={updateTerm}>
            <button className="btn btn-primary">Update</button>
          </Popconfirm>
        </div>
      </div>

      <div>
        <h3 className="m-tb-md">English</h3>
        <Editor
          init={editorConfig}
          initialValue={term?.contentVI}
          onInit={(evt, editor) => (editorViRef.current = editor)}
          tagName="term-vi"
        ></Editor>
        <h3 className="m-tb-md">VietNam</h3>
        <Editor
          init={editorConfig}
          initialValue={term?.contentUS}
          onInit={(evt, editor) => (editorEnRef.current = editor)}
          tagName="term-en"
        ></Editor>
      </div>
    </div>
  );
};

export default TermsAndConditions;
