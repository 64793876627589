import { BackTop } from 'antd';
import { Outlet } from 'react-router-dom';
import Header from './header';
import MenuLeft from './menu-left';
export function MainLayout() {
  return (
    <div className="theme-default" id="theme">
      <MenuLeft></MenuLeft>
      <div className="main-wrapper">
        <Header></Header>
        <div className="main-content">
          <Outlet />
          <BackTop />
        </div>
      </div>
    </div>
  );
}
