import XImage from '@components/Image';
import { CommonHelper } from '@helpers/commonHelper';
import { FileUpload } from '@models/base';
import { Button, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { FunctionComponent, useEffect, useState } from 'react';

interface UploadImageSingleProps {
  title?: string;
  defaultImage?: string;
  src?: string;
  onValue: (file: FileUpload) => void;
}

const UploadImageSingle: FunctionComponent<UploadImageSingleProps> = props => {
  const [url, setUrl] = useState(props.src);

  useEffect(() => {
    console.log(new Date().toISOString());
    if (props.src) setUrl(props.src);
  }, [props.src]);
  const handleImageList = (item: RcFile) => {
    const file = new FileUpload();
    file.fileName = item.name;
    file.mimetype = item.type;
    CommonHelper.getBase64(item, resp => {
      setUrl(resp);
      file!.file = resp;
      if (props.onValue) props.onValue(file);
    });

    return false;
  };

  const uploadButton = (
    <Button type="default" className="fs-12 bold font-open">
      <span className="mat-icon small lh-16 m-r-xxs">publish</span>
      Upload
    </Button>
  );

  return (
    <Upload
      name="avatar"
      listType="picture-card"
      showUploadList={false}
      accept="image/png,image/jpeg,image/gif,image/bmp"
      beforeUpload={handleImageList}
    >
      {url ? (
        <XImage url={url} alt="avatar" defaultImage={props.defaultImage} style={{ width: '100%' }} type="CDN" />
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default UploadImageSingle;
