import { Popconfirm, Tooltip } from 'antd';
import { FunctionComponent } from 'react';

interface ButtonIconProps {
  children: JSX.Element;
  title?: string;
  tooltip?: string;
  onOk?: () => void;
  onClick?: () => void;
}

const ButtonIcon: FunctionComponent<ButtonIconProps> = props => {
  let element;
  if (props.title) {
    element = (
      <span className="pointer mr10">
        <Tooltip title={props.tooltip}>
          <Popconfirm title={props.title} onConfirm={props.onOk} okText="Yes" cancelText="No">
            {props.children}
          </Popconfirm>
        </Tooltip>
      </span>
    );
  } else {
    element = (
      <span className="pointer mr10">
        <Tooltip title={props.tooltip}>{props.children}</Tooltip>
      </span>
    );
  }
  return <span onClick={props.onClick}>{element}</span>;
};

export default ButtonIcon;
