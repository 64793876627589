import { Outlet } from 'react-router-dom';

function BlankLayout() {
  return (
    <div className="theme-default">
      <div className="blank-page flex-center">
        <div className="blank-box max-w800">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
export default BlankLayout;
