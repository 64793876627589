import { Loading } from '@helpers/loading';
import WebHelper from '@helpers/webHelper';
import { PaginatedList, SearchJobs, SearchSimple } from '@models/base';
import { JobDetailItem } from '@models/referrer-payment';
import { Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, Subject } from 'rxjs';
import { CompanyService } from '@services/company.service';
import { Company } from '@models/base/company';
import { JobService } from '@services/job.service';
import { DateHelper } from '@helpers/dateHelper';
const subject = new Subject<any>();
const { Option } = Select;
let searchDataLocal: SearchJobs = new SearchJobs(true);

const JobPage = () => {
  const companyService: CompanyService = CompanyService.instance;
  const jobService: JobService = JobService.instance;
  const [searchCompany, setSearchCompany] = useState<SearchSimple>(new SearchSimple());
  const [list, setList] = useState<JobDetailItem[]>([]);
  const [companys, setCompanys] = useState<Company[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [searchData, setSearchData] = useState<SearchJobs>(new SearchJobs(true));
  const columnDefs: ColumnsType<JobDetailItem> = useMemo<ColumnsType<JobDetailItem>>(
    () => [
      {
        key: 'name',
        title: 'Job title',
        dataIndex: 'title',
        render: value => {
          return <span>{value || '-'}</span>;
        },
      },
      {
        key: 'status',
        title: 'Status',
        dataIndex: 'status',
        render: value => {
          return <span>{value || '-'}</span>;
        },
      },
      {
        title: 'Last Activity',
        key: 'lastAcitivityDate',
        dataIndex: 'lastAcitivityDate',
        render: data => {
          return <span>{DateHelper.formatByLocale(data, 'dd/MM/yyyy', 'en')}</span>;
        },
      },
      {
        title: 'Company',
        key: 'unpaid',
        render: data => {
          return <span>{data.company?.name}</span>;
        },
      },
    ],
    [],
  );
  useEffect(() => {
    loadList();
    loadCompanyList();
  }, []);

  useEffect(() => {
    const subcribe = subject.pipe(debounceTime(500)).subscribe({
      next: v => {
        return search();
      },
    });
    return () => {
      setCompanys([]);
      setSearchData(new SearchJobs(true));
      searchDataLocal = searchData;
      WebHelper.unSubscribe(subcribe);
    };
  }, []);

  function loadList(pagination?: any) {
    if (pagination && pagination.current) {
      searchDataLocal.pageNumber = pagination.current;
    }
    Loading.show();
    jobService.getAll<PaginatedList<JobDetailItem>>(searchDataLocal).subscribe(result => {
      if (result) {
        result.results.forEach((value, index) => {
          value.key = index;
        });
        setList(result.results);
        searchDataLocal.totalItems = result.totalItem;
        searchDataLocal.pageNumber = searchData.pageNumber;
        setSearchData(searchDataLocal);
        Loading.hide();
      }
    });
  }

  const search = () => {
    searchDataLocal.pageNumber = 1;
    loadList();
  };

  const onKeyword = (event: any) => {
    searchDataLocal.keyword = event.target.value;
    setKeyword(event.target.value);
    subject.next(searchData);
  };

  function onSelectedCompany(value: any) {
    searchData.companyId = value;
    search();
  }

  function loadCompanyList() {
    companyService.getDropdown<PaginatedList<Company>>(searchCompany).subscribe(resp => {
      setCompanys([...companys, ...resp.results]);
      searchCompany.totalItems = resp.totalItem;
    });
  }

  function loadMoreCompany(event: any): void {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom) {
      searchCompany.pageNumber++;
      if (searchCompany.valid) {
        loadCompanyList();
      }
    }
  }

  return (
    <div className="custom-scroll p-md">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group width-300 m-b-none m-r-sm d-inline-block">
            <input
              placeholder="Search Name or Email"
              className="form-control have-icon small"
              value={keyword}
              onChange={onKeyword}
            />
            <span className="form-control-icon transform pointer">
              <span className="mat-icon-outline fs-20">search</span>
            </span>
          </div>
          <div className="dropdown m-r-sm d-inline-block">
            <div className="form-group small">
              <Select
                suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                allowClear={true}
                value={searchData.companyId}
                onChange={onSelectedCompany}
                onPopupScroll={loadMoreCompany}
                placeholder="All company"
              >
                {companys.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-12 m-t-lg custom-table">
          <Table
            columns={columnDefs}
            dataSource={list}
            onChange={loadList}
            pagination={{
              hideOnSinglePage: true,
              total: searchData.totalItems,
              pageSize: searchData.pageSize,
              current: searchData.pageNumber,
              showSizeChanger: false,
            }}
          ></Table>
        </div>
      </div>
    </div>
  );
};
export default JobPage;
