import { EnhancedStore } from '@reduxjs/toolkit/dist/configureStore';

export class Constants {
  static countRequest = 0;
  static returnUrl = '';
  static routerPrevious: any;
  static CLIENT_PAGE_SIZE = 20;
  static store: EnhancedStore;
  static readonly TYPE_USER_AUTHEN = {
    referrer: 'referrer@2019#',
    company: 'company@2019#',
    tnm: 'tnm@2019#',
  };
  static readonly PAYMENT_STATUS_ENUM = {
    New: 'request',
    Processing: 'processing',
    Paid: 'done',
    Cancel: 'cancel',
  };
  static readonly PAYMENT_STATUS = [
    { code: Constants.PAYMENT_STATUS_ENUM.New, label: 'New', html: '<span class="bg-yellow">New</span>' },
    {
      code: Constants.PAYMENT_STATUS_ENUM.Processing,
      label: 'Processing',
      html: '<span class="bg-blue-light">Processing</span>',
    },
    { code: Constants.PAYMENT_STATUS_ENUM.Paid, label: 'Paid', html: '<span class="bg-green-light">Paid</span>' },
    // { code: Constants.PAYMENT_STATUS_ENUM.Cancel, label: 'Cancel', html: '<span class="bg-gray">Cancel</span>' },
  ];

  static readonly SETTING_VALUES = [
    { code: 'AcceptCVExpirationTime', label: 'ACCEPT_CV_EXPIRATION_TIME' },
    { code: 'HoldCVExpirationTime', label: 'HOLD_CV_EXPIRATION_TIME' },
  ];

  static TINY_CONFIG: any = {
    suffix: '.min',
    plugins: 'lists image link hr fullscreen help searchreplace table',
    toolbar:
      // eslint-disable-next-line max-len
      'formatselect | bold italic strikethrough forecolor backcolor permanentpen formatpainter | link image media pageembed | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent | removeformat | addcomment',
    image_advtab: true,
    content_css: ['//fonts.googleapis.com/css?family=Lato:300,300i,400,400i', '//www.tiny.cloud/css/codepen.min.css'],
    importcss_append: true,
    height: 300,
    template_cdate_format: '[CDATE: %m/%d/%Y : %H:%M:%S]',
    template_mdate_format: '[MDATE: %m/%d/%Y : %H:%M:%S]',
    image_caption: true,
    spellchecker_dialog: true,
    tinycomments_mode: 'embedded',
    content_style: '.mce-annotation { background: #fff0b7; } .tc-active-annotation {background: #ffe168; color: black; }',
    relative_urls: false,
    remove_script_host: false,
    convert_urls: false,
    document_base_url: process.env.REACT_APP_CDN_URL,
    placeholder: '',
  };
}
