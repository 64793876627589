export class UserModel {
  id?: string;
  _id?: string;
  email?: string;
  fullName?: string;
  isActive?: boolean;
  createdDate?: Date;
  avatar?: string;
  password?: string;
  newPassword?: string;
  phoneNumber?: string;
  type?: string;
  roles?: any[];
}
