import { FunctionComponent } from 'react';

interface ModalHeaderProps {
  title?: string;
}

const ModalHeader: FunctionComponent<ModalHeaderProps> = props => {
  return (
    <div className="header-page pd-left p-r-xs">
      <div className="container-fluid">
        <div className="row">
          {/* {props.title ? <div className="col-md-7">{props.title}</div> : <span />} */}
          <div className="col-md-7">{props.title ? props.title : <span />}</div>
          <div className="col-md-5 text-end">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default ModalHeader;
