import { useMutation } from '@apollo/client';
import ModalHeader from '@components/Modals/header';
import { Constants } from '@config/constant';
import { Toastr } from '@helpers/toastr';
import { PaginatedList, SearchSimple } from '@models/base';
import { Company } from '@models/base/company';
import { CreateUpdateSetting } from '@models/interface';
import { CompanyService } from '@services/company.service';
import { Form, Input, Modal, Select } from 'antd';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { CREATE_UPDATE_COMPANY_SETTING } from './query';
const { Option } = Select;
const AddUpdateModal = forwardRef((props: any, ref) => {
  const { refreshList } = props;
  const companyService: CompanyService = CompanyService.instance;
  const [searchCompany, setSearchCompany] = useState<SearchSimple>(new SearchSimple());
  const [companys, setCompanys] = useState<Company[]>([]);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [dataSend, setDataSend] = useState<CreateUpdateSetting>({} as CreateUpdateSetting);
  const [isEdit, setIsEdit] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const settingValue = Constants.SETTING_VALUES;

  useImperativeHandle(ref, () => ({
    showModal: (data: CreateUpdateSetting) => {
      if (data) {
        const temp = { ...data };
        temp.key = settingValue.find(item => item.label === temp.key)!.code;
        setDataSend(temp);
        form.setFieldsValue({
          value: data.value,
        });
        setIsEdit(true);
      } else {
        setDataSend({} as CreateUpdateSetting);
        form.setFieldsValue({
          value: undefined,
        });
        setIsEdit(false);
      }
      setIsSubmit(false);
      setIsModalVisible(true);
      loadCompanyList();
    },
  }));

  // useEffect(() => {
  //   console.log('sdfdsfsdffs', ref);
  //   console.log('sdfdsfsdffs', props);
  // }, []);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function onSelectedSetting(key: string) {
    dataSend.key = key;
  }

  function onSelectedCompany(companyId: number) {
    dataSend.companyId = companyId;
  }

  function loadCompanyList() {
    companyService.getDropdown<PaginatedList<Company>>(searchCompany).subscribe(resp => {
      setCompanys([...companys, ...resp.results]);
      searchCompany.totalItems = resp.totalItem;
    });
  }

  function loadMoreCompany(event: any): void {
    const bottom = event.target.scrollHeight - event.target.scrollTop === event.target.clientHeight;
    if (bottom) {
      searchCompany.pageNumber++;
      if (searchCompany.valid) {
        loadCompanyList();
      }
    }
  }

  const [submitAction, { data }] = useMutation(CREATE_UPDATE_COMPANY_SETTING, {
    onCompleted(data: any) {
      if (data && data.creatAndUpdateSetting && data.creatAndUpdateSetting.id > 0) {
        Toastr.success(data.creatAndUpdateSetting.message);
        refreshList();
        handleCancel();
      } else if (data.creatAndUpdateSetting.id < 0) {
        Toastr.error('Not Succeed. Setting Existed.');
      }
    },
  });

  function handleOk(values: any) {
    setIsSubmit(true);
    if (!dataSend.companyId || !dataSend.key) return;
    const data = { ...dataSend };
    data.value = +values.value;
    submitAction({ variables: data });
  }

  return (
    <div>
      <Modal footer={null} closable={false} visible={isModalVisible}>
        <Form
          onFinish={handleOk}
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          <ModalHeader>
            <button className="btn btn-medium btn-primary m-r-sm">Save</button>
            <button type="button" className="btn btn-medium" onClick={handleCancel}>
              Close
            </button>
          </ModalHeader>
          <div className="p-lr-md p-b-md">
            <div className="m-b-sm">
              <label className="d-block text-black-light m-b-xxs">Company</label>
              <Select
                className="full-width"
                value={dataSend.companyId}
                onChange={onSelectedCompany}
                onPopupScroll={loadMoreCompany}
                placeholder="Select company"
                disabled={isEdit}
                status={isSubmit && !dataSend.companyId ? 'error' : ''}
              >
                {companys.map(item => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="m-b-sm">
              <label className="d-block text-black-light m-b-xxs">Key</label>
              <Select
                className="full-width"
                value={dataSend.key}
                onChange={onSelectedSetting}
                placeholder="Select key"
                disabled={isEdit}
                status={isSubmit && !dataSend.key ? 'error' : ''}
              >
                {settingValue.map(item => (
                  <Option key={item.code} value={item.code}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="m-b-sm">
              <label className="d-block text-black-light m-b-xxs">Value</label>
              <Form.Item name="value" rules={[{ required: true }]}>
                <Input placeholder="Value" type={'number'} />
              </Form.Item>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
});

export default AddUpdateModal;
