import { Constants } from '@config/constant';
import { Loading } from '@helpers/loading';
import WebHelper from '@helpers/webHelper';
import { PaginatedList, SearchJobs, SearchSimple } from '@models/base';
import { CompanyItem } from '@models/referrer-payment';
import { Popconfirm, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useState } from 'react';
import { debounceTime, Subject } from 'rxjs';
import { CompanyService } from '@services/company.service';
import { Toastr } from '@helpers/toastr';
import { GeneratePassword } from '@helpers/generatePassword';
const subject = new Subject<any>();
let searchDataLocal: SearchSimple = new SearchSimple(true);
const CompanyPage = () => {
  const companyService: CompanyService = CompanyService.instance;
  const [list, setList] = useState<CompanyItem[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [searchData, setSearchData] = useState<SearchSimple>(new SearchSimple(true));
  const columnDefs: ColumnsType<CompanyItem> = useMemo<ColumnsType<CompanyItem>>(
    () => [
      {
        key: 'name',
        title: 'Company Name',
        dataIndex: 'name',
        render: value => {
          return <span>{value || '-'}</span>;
        },
      },
      {
        key: 'Email',
        title: 'email',
        dataIndex: 'email',
        render: value => {
          return <span>{value || '-'}</span>;
        },
      },
      {
        title: 'Tax Code',
        key: 'taxCode',
        dataIndex: 'taxCode',
        render: data => {
          return <span>{data}</span>;
        },
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        render: data => {
          return <span>{data}</span>;
        },
      },
      {
        title: '',
        key: 'action',
        render: data => {
          return (
            <div className="text-end">
              {data.status === 'processing' && (
                <Popconfirm title="Are you sure?" onConfirm={() => approveCompany(data)} okText="Yes" cancelText="No">
                  <button nz-popconfirm className="btn btn-primary btn-small">
                    Approve
                  </button>
                </Popconfirm>
              )}
            </div>
          );
        },
      },
    ],
    [],
  );
  useEffect(() => {
    loadList();
  }, []);

  useEffect(() => {
    const subcribe = subject.pipe(debounceTime(500)).subscribe({
      next: v => {
        return search();
      },
    });
    return () => {
      setSearchData(new SearchSimple(true));
      WebHelper.unSubscribe(subcribe);
    };
  }, []);

  function loadList(pagination?: any) {
    if (pagination && pagination.current) {
      searchDataLocal.pageNumber = pagination.current;
    }
    Loading.show();
    companyService.getAll<PaginatedList<CompanyItem>>(searchDataLocal).subscribe(result => {
      if (result) {
        result.results.forEach((value, index) => {
          value.key = index;
        });
        setList(result.results);
        searchDataLocal.totalItems = result.totalItem;
        searchDataLocal.pageNumber = searchData.pageNumber;
        setSearchData(searchDataLocal);
        Loading.hide();
      }
    });
  }

  const search = () => {
    searchDataLocal.pageNumber = 1;
    loadList();
  };

  const onKeyword = (event: any) => {
    searchDataLocal.keyword = event.target.value;
    setKeyword(event.target.value);
    subject.next(searchData);
  };

  function approveCompany(item: CompanyItem) {
    const body = {
      id: item.id,
      password: GeneratePassword.generatePw({ length: 10, numbers: true }),
    };
    companyService.add(body).subscribe((res: any) => {
      if (res && res.id > 0) {
        Toastr.success('Successfully');
      }
    });
  }

  function generatePw(value: boolean) {
    if (value) {
      const pw = GeneratePassword.generatePw({
        length: 10,
        numbers: true,
      });
    }
  }

  return (
    <div className="custom-scroll p-md">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group width-300 m-b-none m-r-sm d-inline-block">
            <input
              placeholder="Search Name or Email"
              className="form-control have-icon small"
              value={keyword}
              onChange={onKeyword}
            />
            <span className="form-control-icon transform pointer">
              <span className="mat-icon-outline fs-20">search</span>
            </span>
          </div>
        </div>
        <div className="col-md-12 m-t-lg custom-table">
          <Table
            columns={columnDefs}
            dataSource={list}
            onChange={loadList}
            pagination={{
              hideOnSinglePage: true,
              total: searchData.totalItems,
              pageSize: searchData.pageSize,
              current: searchData.pageNumber,
              showSizeChanger: false,
            }}
          ></Table>
        </div>
      </div>
    </div>
  );
};
export default CompanyPage;
