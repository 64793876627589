import LoadingOverlay from 'react-loading-overlay-ts';
import { css } from '@emotion/react';
import { BounceLoader } from 'react-spinners';
import { PubSubService } from '@helpers/services/pubsub.service';
import { PubSubEvent } from '@helpers/services/pub-subevent';
import React from 'react';
import WebHelper from '@helpers/webHelper';

interface GlobalLoaderProps {
  children: any;
}

interface GlobalLoaderState {
  isActive: boolean;
}
const override: any = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
class GlobalLoader extends React.Component<GlobalLoaderProps, GlobalLoaderState> {
  subEvent: any;
  subEventClear: any;
  count = 0;
  constructor(props: GlobalLoaderProps) {
    super(props);
    this.state = { isActive: false };
    this.subEvent = PubSubService.instance.$sub(PubSubEvent.EVENTS.ON_SHOW_LOADING).subscribe((result: boolean) => {
      this.setState({
        isActive: result,
      });
      // if (result === false) {
      //   if (this.count > 0) {
      //     this.count--;
      //   }
      //   if (this.count <= 0) {
      //     this.setState({
      //       isActive: false,
      //     });
      //   }
      // } else {
      //   if (this.count <= 0) {
      //     this.setState({
      //       isActive: result,
      //     });
      //   }
      //   this.count++;
      // }
      // console.log('count', this.count, result);
    });
    this.subEventClear = PubSubService.instance.$sub(PubSubEvent.EVENTS.ON_CLEAR_LOADING).subscribe(() => {
      this.count = 0;
      this.setState({
        isActive: false,
      });
    });
  }
  componentWillUnmount() {
    WebHelper.unSubscribe(this.subEvent, this.subEventClear);
  }
  render() {
    return (
      <LoadingOverlay
        active={this.state.isActive}
        spinner={<BounceLoader css={override} color="#13D2B1" />}
        styles={
          {
            overlay: (base: any) => ({
              ...base,
              background: '#b0b0b057',
              'z-index': 9000,
            }),
          } as any
        }
      >
        {this.props.children}
      </LoadingOverlay>
    );
  }
}

export default GlobalLoader;
