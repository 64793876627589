import { CookieStorage } from './cookieStorage';

export class CookieHelper {
  static authenKey = 'aluha.intranet.pro';

  static createStore(key: string, data: any) {
    if (data !== null && data !== undefined) {
      CookieStorage.createStore(key, data);
    }
  }
  static removeStore(key: string) {
    if (key) {
      CookieStorage.removeStore(key);
    }
  }

  static getCookieToken(key = this.authenKey) {
    return CookieStorage.getStore(key);
  }
}
