import { DateHelper } from './dateHelper';

export class UrlHelper {
  static getValue(
    router: URLSearchParams,
    key: string,
    formatDate?: string,
    type?: 'Date' | 'string' | 'number' | 'boolean',
    defauleValue?: any,
  ) {
    if (router.has(key)) {
      const value = router.get(key);
      if (key.indexOf('Date') !== -1 || type === 'Date') {
        let date = value ? new Date(value) : defauleValue || new Date();
        if (formatDate) {
          date = DateHelper.formatServer(date, formatDate);
        }
        return date;
      }
      if (key.indexOf('skip') !== -1 || key.indexOf('limit') !== -1 || type === 'number') {
        // tslint:disable-next-line: radix
        return value ? parseInt(value) : defauleValue || 0;
      }
      if (type === 'boolean' || value === 'true' || value === 'false') {
        return value ? value === 'true' : defauleValue || false;
      }
      return value;
    }
    return undefined;
  }
  static mappingData(router: URLSearchParams, target: any, formatDate = 'YYYY-MM-DD') {
    for (const entry of router.entries()) {
      const [param] = entry;
      if (target.hasOwnProperty(param)) {
        const value = this.getValue(router, param, formatDate);
        if (param === 'pageSize' || param === 'pageNumber') {
          target[param] = parseInt(value, 10);
        } else {
          target[param] = value;
        }
      }
    }
    return target;
  }

  static getStartTour() {
    const path = window.location.pathname;
    let start;
    if (path === '/company/job') {
      start = 'job.search';
    } else if (path === '/company/company-dashboard') {
      start = 'header.tour';
    } else if (path === '/company/candidate') {
      start = 'candidate.search';
    } else if (path === '/company/pipeline') {
      start = 'header.pipeline';
    } else if (path === '/company/schedule') {
      start = 'header.schedule';
    } else if (path === '/company/setting/user-permission') {
      start = 'header.settings';
    }
    return start;
  }
}
