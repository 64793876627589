import { AluhaMenu } from '@models/menu';
import { v4 as uuid } from 'uuid';
import { UrlConstant } from './urlConstant';

export class MenuHelper {
  static menusListTNM: AluhaMenu[] = [
    {
      id: uuid(),
      icon: 'insights',
      title: 'Dashboard',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.DASHBOARD),
    },
    {
      id: uuid(),
      icon: 'maps_home_work',
      title: 'Companies',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.COMPANY.LIST),
      checkPermission: true,
    },
    {
      id: uuid(),
      icon: 'work_outline',
      title: 'Jobs',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.JOB),
      checkPermission: true,
    },
    {
      id: uuid(),
      icon: 'payments',
      title: 'Payment',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.PAYMENT.ROOT),
      subPermission: ['/admin/payment'],
      checkPermission: true,
    },
    {
      id: uuid(),
      icon: 'settings',
      title: 'Settings',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.SETTINGS.TERMS),
      checkPermission: true,
    },
    {
      id: uuid(),
      icon: 'manage_accounts',
      title: 'User',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.USER),
      checkPermission: true,
    },
    {
      id: uuid(),
      icon: 'person',
      title: 'Profile',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.PROFILE),
    },
    // {
    //   id: uuid(),
    //   icon: 'ft-user',
    //   title: 'menu.group_user',
    //   url: UrlConstant.mapTNM(UrlConstant.TNMUrl.GROUP_USER.LIST),
    // },
    // {
    //   id: uuid(),
    //   icon: 'ft-settings',
    //   title: 'menu.setting',
    //   childrens: [
    //     {
    //       id: uuid(),
    //       title: 'menu.tag',
    //       url: UrlConstant.mapTNM(UrlConstant.TNMUrl.SETTINGS.TAG, 'setting'),
    //       controller: 'tag',
    //       action: 'list',
    //     },
    //     {
    //       id: uuid(),
    //       title: 'menu.bank-setting',
    //       url: UrlConstant.mapTNM(UrlConstant.TNMUrl.SETTINGS.BANK_INFO, 'setting'),
    //       controller: 'admin-menu',
    //       action: 'admin-bank-config',
    //     },
    //     {
    //       id: uuid(),
    //       title: 'menu.contact-setting',
    //       url: UrlConstant.mapTNM(UrlConstant.TNMUrl.SETTINGS.CONTACT, 'setting'),
    //       controller: 'admin-menu',
    //       action: 'admin-contact-view',
    //     },
    //     {
    //       id: uuid(),
    //       title: 'menu.mail-server',
    //       url: UrlConstant.mapTNM(UrlConstant.TNMUrl.SETTINGS.MAIL_SERVER, 'setting'),
    //       controller: 'mailserver',
    //       action: 'mail-server-config',
    //     },
    //     {
    //       id: uuid(),
    //       title: 'menu.term-setting',
    //       url: UrlConstant.mapTNM(UrlConstant.TNMUrl.TERMs_CONDITIONS, 'setting'),
    //       controller: 'confignextmove',
    //       action: 'get-page-setting',
    //     },
    //   ],
    // },
  ];

  static menusPayment: AluhaMenu[] = [
    {
      id: uuid(),
      title: 'Referrers',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.PAYMENT.ROOT),
      checkPermission: true,
    },
    {
      id: uuid(),
      title: 'Companies',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.PAYMENT.COMPANY),
      customUrl: UrlConstant.mapTNM(UrlConstant.TNMUrl.PAYMENT.ROOT),
      checkPermission: true,
    },
  ];

  static menusSetting: AluhaMenu[] = [
    {
      id: uuid(),
      title: 'Terms',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.SETTINGS.TERMS),
      checkPermission: true,
    },
    {
      id: uuid(),
      title: 'Key Setting',
      url: UrlConstant.mapTNM(UrlConstant.TNMUrl.SETTINGS.KEY_SETTING),
      customUrl: UrlConstant.mapTNM(UrlConstant.TNMUrl.SETTINGS.ROOT),
      checkPermission: true,
    },
  ];
}
