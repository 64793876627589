import { PubSubEvent } from './services/pub-subevent';
import { PubSubService } from './services/pubsub.service';

export class Loading {
  static show() {
    PubSubService.instance.$pub(PubSubEvent.EVENTS.ON_SHOW_LOADING, true);
  }
  static hide() {
    PubSubService.instance.$pub(PubSubEvent.EVENTS.ON_SHOW_LOADING, false);
  }
  static reset() {
    PubSubService.instance.$pub(PubSubEvent.EVENTS.ON_CLEAR_LOADING, false);
  }
}
