import { PubSubEvent, PubSubService } from '@helpers/services';
import WebHelper from '@helpers/webHelper';
import { SortItem } from '@models/enums';
import { FunctionComponent, useEffect, useState } from 'react';
interface SortInterface {
  title: string;
  field: string;
  clickSort: (value: SortItem) => void;
}
const Sort: FunctionComponent<SortInterface> = ({ title, field, clickSort }) => {
  const [options, setOption] = useState(new SortItem());
  const [count, setCount] = useState(0);

  useEffect(() => {
    const subEvent = PubSubService.instance.$sub(PubSubEvent.EVENTS.RESET_SORT).subscribe((data: SortItem) => {
      setOption(new SortItem());
      setCount(0);
    });
    return () => {
      WebHelper.unSubscribe(subEvent);
    };
  }, []);

  function selectSort() {
    PubSubService.instance.$pub(PubSubEvent.EVENTS.RESET_SORT);
    options.sortField = field;
    const optionsTemp = { ...options };
    if (count === 0) {
      optionsTemp.sortFieldType = 'DESC';
      setCount(1);
    } else if (count === 1) {
      optionsTemp.sortFieldType = 'ASC';
      setCount(2);
    } else if (count === 2) {
      optionsTemp.sortFieldType = undefined || '';
      optionsTemp.sortField = undefined || '';
      setCount(0);
    }

    setOption(optionsTemp);
    clickSort(optionsTemp);
  }

  return (
    <span
      className={`table-label ${options.sortFieldType === 'DESC' ? 'text-black' : ''} ${
        options.sortFieldType !== 'DESC' ? 'text-black-light' : ''
      }`}
    >
      <span className="table-label">{title}</span>
      <span onClick={selectSort} className="m-l-xxs mat-icon-outline fs-15 top_2 pointer table-icon">
        {options.sortFieldType === 'DESC' ? 'arrow_downward' : options.sortFieldType === 'ASC' ? 'arrow_upward' : 'sort'}
      </span>
    </span>
  );
};

export default Sort;
