import { Constants } from '@config/constant';
import { DateHelper } from '@helpers/dateHelper';
import { PagingSortenum } from '@models/enums';

export class SearchPagination {
  keyWord?: string;
  pageNumber: number;
  pageSize: number;
  sortField?: string;
  sortFieldType: PagingSortenum;

  constructor(searchText?: string, pageNumber?: number, pageSize?: number, sortField?: string, sortType?: PagingSortenum) {
    this.keyWord = searchText ? searchText : '';
    this.pageNumber = pageNumber ?? 1;
    this.pageSize = pageSize ?? Constants.CLIENT_PAGE_SIZE;
    this.sortField = sortField;
    this.sortFieldType = sortType ?? PagingSortenum.ASC;
  }
}

export class SearchModel {
  keyword?: string;
  startDate?: Date;
  endDate?: Date;
  pageNumber = 0;
  pageSize = 0;
  isGenerate?: boolean;
  totalItems = 0;
  nonPaging?: boolean;
  noLoading?: boolean;
  ignores?: string[] = [];
  sortField?: string;
  sortFieldType?: string = 'ASC' || 'DESC';
  totalPages = 0;
  formatDate(hasTime = false) {
    if (hasTime) {
      this.startDate = DateHelper.formatServer(this.startDate);
      this.endDate = DateHelper.formatServer(this.endDate);
    } else {
      this.startDate = DateHelper.formatServerNotTime(this.startDate);
      this.endDate = DateHelper.formatServerNotTime(this.endDate);
    }
  }

  calTotalPage() {
    this.totalPages = Math.ceil(this.totalItems / this.pageSize);
  }

  get valid(): boolean {
    return this.pageNumber <= this.totalPages;
  }

  setTotalItems(lists: any[]) {
    if (lists && lists.length) {
      this.totalItems = lists[0].totalCount;
    } else {
      this.totalItems = 0;
    }
  }
  setTotalItem(total: number) {
    this.totalItems = total;
  }

  constructor(isGenerate = false) {
    this.keyword = '';
    // this.pagination = new Page();
    this.ignores = ['pagination', 'totalItems', 'noLoading'];
    this.pageNumber = 0;
    this.pageSize = 20;
    this.isGenerate = isGenerate;
    this.noLoading = false;
    this.startDate = undefined;
    this.endDate = undefined;
    this.sortFieldType = undefined;
    this.sortField = undefined;
  }
}
export class SearchSimple extends SearchModel {
  candidatePipeLineId?: number;
  ownerId?: string;
  jobId?: number;
  constructor(isGenerate = false, pageSize = 20) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}
export class CandidateFilter extends SearchModel {
  candidatePipeLineId?: number;
  constructor(isGenerate = false, pageSize = 20) {
    super(isGenerate);
    this.pageSize = pageSize;
    this.pageNumber = 1;
  }
}

export class SearchCandidate extends SearchModel {
  source: string;
  status?: string;
  jobId?: number;
  constructor(isGenerate = false, pageSize = 20) {
    super(isGenerate);
    this.pageSize = pageSize;
    this.pageNumber = 1;
  }
}

export class SearchJob extends SearchModel {
  candidateId?: string;
  jobId?: number;
  location?: string;
  date?: string;
  type?: number;
  salaryStart?: number;
  salaryEnd?: number;
  rewardStart?: number;
  rewardEnd?: number;
  companySlug?: string;
  companyName?: string;
  // reward?: {start: number, end: number};
  // salary?: {start: number, end: number};
  constructor(isGenerate = false, pageSize = 20) {
    super(isGenerate);
    this.pageSize = pageSize;
    this.pageNumber = 1;
    this.candidateId = undefined;
    this.location = undefined;
    this.jobId = undefined;
    this.salaryStart = undefined;
    this.salaryEnd = undefined;
    this.companySlug = undefined;
    this.companyName = undefined;
  }
}

export class SearchCandidateBasic extends SearchModel {
  source: number;
  ownerId?: string;
  stage?: string;
  location?: number;
  skill?: string;
  hiringManagerId?: string;
  groupId?: string;
  jobId?: number;
  constructor(isGenerate = false, pageSize = 20) {
    super(isGenerate);
    this.ownerId = undefined;
    this.location = undefined;
    this.stage = undefined;
    this.skill = undefined;
    this.hiringManagerId = undefined;
    this.groupId = undefined;
    this.pageSize = pageSize;
    this.pageNumber = 1;
  }
}
export class SearchUser extends SearchModel {
  keyWord?: string | undefined;
  constructor(isGenerate = false, pageSize = 20) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}
export class SearchRefer extends SearchModel {
  period?: number;
  status?: string;
  constructor(isGenerate = false, pageSize = 20) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchCompanyPayment extends SearchModel {
  status?: string;
  constructor(isGenerate = false, pageSize = 20) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchPaymentPipeline extends SearchModel {
  companyId?: number;
  probationStartTime?: number;
  employeeStartTime?: number;
  status?: string;

  constructor(isGenerate = false, pageSize = 20) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchJobs extends SearchModel {
  companyId?: number;

  constructor(isGenerate = false, pageSize = 20) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}

export class SearchCompanySetting extends SearchJobs {
  constructor(isGenerate = false, pageSize = 20) {
    super();
    this.pageSize = pageSize;
    this.isGenerate = isGenerate;
    this.pageNumber = 1;
  }
}
