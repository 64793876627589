import { BaseService } from '@helpers/https';

export class CompanyPaymentWalletService extends BaseService {
  hostUrl = this.host + '/payment/company-wallet-managements';
  private static _instance: CompanyPaymentWalletService;
  static get instance() {
    if (!this._instance) this._instance = new CompanyPaymentWalletService();
    return this._instance;
  }

  getCompanyWalletByCompanyId(companyId: number) {
    const url = `company/${companyId}`;
    return this.get<any>(url, { noLoading: true });
  }

  getDebtDetail(companyId: number) {
    const url = `company/${companyId}/debt-detail`;
    return this.get<any>(url, { noLoading: true });
  }
}
