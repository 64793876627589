import axios from 'axios';
import axios2 from 'axios-observable';
import { lastValueFrom, Observable, Observer } from 'rxjs';
import { SecurityHelper } from '../securityHelper';
import * as momentTimeZone from 'moment-timezone';
import { handleError } from './error';
const axiosInstance = axios.create({
  timeout: 30000,
});
const axiosInstance2 = axios2.create({
  timeout: 30000,
});

const setUserInfo = (data: any, isPost = true) => {
  if (data) {
    const authToken = SecurityHelper.getStoreAuthen();
    if (authToken && authToken.user) {
      data.userId = authToken.user.id;
      if (isPost) {
        data.createdBy = authToken.user.id;
      } else {
        data.updateBy = authToken.user.id;
      }
    }
  }
};

const getHeader = (param?: any, isSetToken = true) => {
  const headers: any = {};
  const timeZone = momentTimeZone.tz.guess();
  headers['Access-Control-Allow-Origin'] = process.env.ADMIN_FE_URL;
  headers['timezone'] = timeZone;
  if (param && param.headers) {
    const listKeys = Object.keys(param.headers).map(key => {
      return { name: key, value: param.headers[key] };
    });
    listKeys.forEach(item => {
      headers[item.name] = item.value;
    });
  }
  const authToken = SecurityHelper.getStoreAuthen();
  if (authToken && authToken.token && isSetToken) {
    headers['Authorization'] = authToken.token || '';
    if (authToken.user && authToken.user.fullName) {
      headers['fullname'] = authToken.user?.fullName.toString().removeVietnamese() || '';
    }
  }
  let httpOptions = { headers };
  if (param) {
    if (param.params) {
      const ignores = param.params?.ignores || [];
      const params = new URLSearchParams();
      const paramsUrl: any = {};
      const listKeys = Object.keys(param.params).map(key => {
        return { name: key, value: param.params[key] };
      });
      let queryParamsString = '';
      listKeys.forEach(item => {
        if (item.name !== 'isGenerate' && item.name !== 'ignores') {
          params.append(item.name, item.value);
          const check = ignores.find((x: any) => x === item.name);
          if (item.value !== undefined && item.value !== null && !check) {
            paramsUrl[item.name] = item.value;
            if (queryParamsString !== '') {
              queryParamsString += '&';
            }
            queryParamsString += item.name + '=' + encodeURIComponent(item.value);
          }
        }
      });
      httpOptions = { ...httpOptions, ...{ params } };
      if (param.params.isGenerate) {
        const newUrl = window.location.pathname + '?' + queryParamsString;
        window.location.replace(newUrl);
      }
    }
    if (param.options) {
      httpOptions = { ...httpOptions, ...param.options };
    }
  }
  return httpOptions;
};

const getSync2 = <T>(url: any, params?: any): Observable<T> => {
  const options = getHeader(params);
  return new Observable<T>((observer: Observer<T>) => {
    axiosInstance.get(url, options).catch(error => {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    });
    // .pipe(
    //   map((res: any) => {
    //     console.log(res);
    //     const data = res ? res.data : undefined;
    //     if (data && data.data && data.isEncry) {
    //       res = SecurityHelper.decrypt(data.data, true, SecurityHelper.token);
    //       delete res.isEncry;
    //     }
    //     if (data && data.data && data.roles) {
    //       const roles = data.roles;
    //       res = data;
    //       const authToken = SecurityHelper.getStoreAuthen();
    //       if (authToken && authToken.user) {
    //         authToken.user.roles = roles;
    //         SecurityHelper.createStoreAuthen(authToken);
    //       }
    //     }
    //     return res;
    //   }),
    // )
    // .subscribe({
    //   next: (res: any) => {
    //     observer.next(res);
    //   },
    //   error: error => {
    //     handleError(error);
    //     observer.error(error);
    //   },
    //   complete: () => {
    //     observer.complete();
    //   },
    // });
  });
};
const deleteSync = <T>(url: any, params?: any): Observable<T> => {
  return new Observable<T>((observer: Observer<T>) => {
    axiosInstance2.delete(url, getHeader(params)).subscribe({
      next: (res: any) => {
        if (res && res.data && res.data.data && res.data.isEncry) {
          res.data = SecurityHelper.decrypt(res.data, true, SecurityHelper.token);
        }
        observer.next(res.data);
      },
      error: error => {
        observer.error(handleError(error));
      },
      complete: () => {
        observer.complete();
      },
    });
  });
};
const postSync = (url: string, data?: any, params: any = {}, isOption = false): Observable<any> => {
  if (!isOption) {
    setUserInfo(params);
  }
  return new Observable<any>((observer: Observer<any>) => {
    axiosInstance2.post(url, data, getHeader(params)).subscribe({
      next: (res: any) => {
        if (res.data && res.data.data && res.data.isEncry) {
          res.data = SecurityHelper.decrypt(res.data.data, true, SecurityHelper.token);
        }
        observer.next(res.data);
      },
      error: error => {
        observer.error(handleError(error));
      },
      complete: () => {
        observer.complete();
      },
    });
  });
};
const putSync = (url: string, params?: any, isOption = false): Observable<any> => {
  if (!isOption) {
    setUserInfo(params);
  }
  return new Observable<any>((observer: Observer<any>) => {
    axiosInstance2.put(url, params, getHeader()).subscribe({
      next: (res: any) => {
        if (res.data && res.data.data && res.data.isEncry) {
          res.data = SecurityHelper.decrypt(res.data.data, true, SecurityHelper.token);
        }
        observer.next(res.data);
      },
      error: (error: any) => {
        observer.error(handleError(error));
      },
      complete: () => {
        observer.complete();
      },
    });
  });
};

const get = (url: any, params?: any) => {
  return lastValueFrom(axiosInstance2.get(url, getHeader(params)));
};
const post = (url: string, data?: any, params: any = {}) => {
  setUserInfo(params);
  return lastValueFrom(axiosInstance2.post(url, data, getHeader(params)));
};
const put = (url: string, data?: any, params: any = {}) => {
  setUserInfo(params);
  return lastValueFrom(axiosInstance2.put(url, data, getHeader(params)));
};

const getExternal = (url: any, params?: any) => {
  return axiosInstance.get(url, getHeader(params, false));
};
const deleteExternal = (url: any, params?: any) => {
  return axiosInstance.delete(url, getHeader(params, false));
};
const postExternal = (url: string, params: any) => {
  return new Promise((resolve, reject) => {
    axiosInstance2.post(url, params).subscribe({
      next: (res: any) => {
        resolve(res);
      },
      error: error => {
        reject(error);
      },
    });
  });
};
const putExternal = (url: string, params: any) => {
  return axiosInstance.post(url, params);
};

const AxisSystem2 = {
  get,
  getSync2,
  getExternal,
  post,
  postSync,
  postExternal,
  put,
  putSync,
  putExternal,
  deleteSync,
  deleteExternal,
};
export default AxisSystem2;
