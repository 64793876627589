import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import MyComponent from '@pages/Counter';
import { useEffect } from 'react';
import { GlobalEvent } from '@helpers/services/global-event';

export function HomePage() {
  useEffect(() => {
    GlobalEvent.updatePageTitle();
  }, []);
  return (
    <>
      <Helmet>
        <title>Home Page</title>
        <meta name="description" content="A application homepage" />
      </Helmet>
      <MyComponent></MyComponent>
    </>
  );
}
