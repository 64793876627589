import { BaseService } from '@helpers/index';
import { SecurityHelper } from '@helpers/securityHelper';
import { ApiResponse } from '@models/api-response.model';
import { UserModel } from '@models/users/user';

export class UserService extends BaseService {
  private static _instance: UserService;
  static get instance() {
    if (!this._instance) this._instance = new UserService();
    return this._instance;
  }
  hostUrl = this.host + '/auth/user_admin';

  getRole<T>() {
    const url = '/get-role';
    return this.get<T>(url);
  }

  deleteUser(id?: string) {
    const url = '/delete/' + id;
    return this.delete(url);
  }

  create(data: UserModel) {
    const url = 'create';
    return this.post<ApiResponse>(url, data);
  }

  getById<T>(id?: string) {
    const url = `get-by-id/${id}`;
    return this.get<T>(url);
  }
  getProfile<T>() {
    const url = `get-profile`;
    return this.get<T>(url);
  }

  getList(data: any) {
    const url = `get-list`;
    return this.get<any>(url, { params: data });
  }

  editProfile(data: any) {
    const url = this.host + '/auth/user/update-info';
    return this.postUrl<ApiResponse>(url, data);
  }

  changePassword(data: any) {
    const url = 'update-internal-password';
    return this.post(url, data);
  }

  checkPassword(data: any) {
    const url = 'check-credential';
    return this.post(url, data);
  }

  forgotPassword(email: string) {
    const url = `forgot-password`;
    return this.post(url, { email });
  }

  checkForgotPasswordToken(token: any) {
    const url = '/check-forgot-password-token';
    return this.post(url, { token });
  }

  setNewPassword(id: any, password: any, token: any) {
    const url = `/set-new-password/${id}`;
    return this.post(url, {
      password,
      token,
    });
  }

  resetPassword(id: any, password: any) {
    const url = `reset-password/${id}`;
    return this.post(url, { password });
  }

  checkAccessPassword(password: string) {
    if (password === 'tnm@2021') {
      SecurityHelper.createStore('access.page', 'tnm@2021');
      return true;
    } else {
      return false;
    }
  }

  createNewPassword(data: any) {
    const url = `/create-new-password`;
    return this.post(url, data);
  }

  verification(email: string, token: string) {
    const url = '/verification?email=' + email + '&token=' + token;
    return this.post(url, null);
  }

  resendByEmail(email: string) {
    const url = `/resend-mail-verify-register?email=${email}`;
    return this.put(url, null);
  }

  userRefer(userId: string) {
    const url = this.host + `/auth/user_refers/${userId}`;
    return this.getUrl<any>(url);
  }
}
