import { BaseService } from '@helpers/https';

export class ReferrerPaymentService extends BaseService {
  hostUrl = this.host + '/payment/refer-value-candidate';
  private static _instance: ReferrerPaymentService;
  static get instance() {
    if (!this._instance) this._instance = new ReferrerPaymentService();
    return this._instance;
  }
  getReferValueByUser(userId: string) {
    const url = `${userId}`;
    return this.get<any>(url, { noLoading: true });
  }
}
