export class ReferrerPayment {
  id: number;
  key: number;
  user: {
    fullName: string;
    email: string;
  };
  userId: string;
  refers: number;
  probationHire: number;
  employee: number;
  employeePaid: number;
  period: string;
  status: string;
  canPaid: boolean;
  get nonRefers(): number {
    return this.employee - this.employeePaid;
  }
}

export class PaymentInfo {
  income: number;
  overbalance: number;
  tnmDebt: number;
  withdrawFee: number;
}

export class PaymentCandidate {
  canPaid: boolean;
  key: number;
  company: any;
  employeeStartTime: string;
  fullName: string;
  id: number;
  candidatePipeLineId: number;
  job: any;
  overdue: boolean;
  probationStartTime: string;
  referStatus: string;
  referWithdrawFee: string;
  reward: string;
  visible?: boolean;
  rewardTemp?: number;
  isCheck?: boolean;
}

export class CompanyPayment {
  key: number;
  companyId: number;
  employee: number;
  employeePaid: number;
  status: string;
  wallet: {
    debt: number;
  };
  company: {
    name: string;
    email: string;
  };
}

export class CompanyPaymentValue {
  companyId: number;
  employee: number;
  employeePaid: number;
}

export class CompanyPaymentWallet {
  companyId: number;
  income: number;
  withdrawFee: number;
  debt: number;
}

export class CompanyPaymentCandidate {
  id: number;
  key: number;
  candidatePipeLineId: number;
  fullName: string;
  employeeStartTime: string;
  reward: number;
  job: any;
  companyWithdrawFee: number;
  companyStatus: string;
  overdue: boolean;
  canPaid: boolean;
  visible?: boolean;
}

export class CompanyDebtDetail {
  new: number;
  processing: number;
}

export class JobDetailItem {
  key: number;
  title: string;
  status: string;
  lastAcitivityDate: Date;
  company: { name: string };
}

export class CompanyItem {
  id: number;
  key: number;
  name: string;
  email: string;
  taxtCode: string;
  status: string;
}
