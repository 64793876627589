/*
 *
 * Brand actions
 *
 */

import { GET_ON_OFF_ACTION } from './constants';

export const testOnOf = (searchData: any) => ({
  type: GET_ON_OFF_ACTION,
  searchData,
});
