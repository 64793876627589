import { gql } from '@apollo/client';

const GET_USERS = gql`
  query user($sortFieldType: PagingSortenum, $pageSize: Int!, $pageNumber: Int!) {
    list(sortFieldType: $sortFieldType, pageSize: $pageSize, pageNumber: $pageNumber) {
      hashData
    }
  }
`;

const CREATE_USER = gql`
  mutation createUser($input: CreateUserAdmin!) {
    createUser(userInput: $input) {
      id
      message
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser($input: UpdateUserAdmin!) {
    updateUser(userInput: $input) {
      id
      message
    }
  }
`;

const DELETE_USER = gql`
  mutation user($id: String!) {
    deleteUser(id: $id) {
      id
      message
    }
  }
`;
export { GET_USERS, CREATE_USER, UPDATE_USER, DELETE_USER };
