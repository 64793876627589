import { createSelector } from 'reselect';

import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: any) => state.user || initialState;
export const selectUsers = createSelector([selectDomain], userState => {
  return userState.data;
});
export const selectSearchData = createSelector([selectDomain], userState => userState.searchData);
export const selectUserData = createSelector([selectDomain], userState => userState.userData);
