import ModalHeader from '@components/Modals/header';
import { Permission } from '@models/base/permission';
import { UserModel } from '@models/users/user';
import { Checkbox, Collapse, Form, Input, Modal, Row as ARow, Col as ACol } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useUserCreateSlice } from './slice';
import { selectUserInput, selectVisible } from './redux-saga';
import { Constants } from '@config/constant';
import { UserService } from '@services/user.service';
import { Loading } from '@helpers/loading';
import linq from 'linq';

const { Panel } = Collapse;
interface CreateUpdateUserProps {
  user?: UserModel;
  title: string;
}

const CreateUpdateUser: FunctionComponent<CreateUpdateUserProps> = props => {
  const userService = UserService.instance;
  const { createActions } = useUserCreateSlice();
  const dispatch = useDispatch();
  const isVisible = useSelector(selectVisible);
  const userSelect: UserModel = useSelector<{}, UserModel>(selectUserInput);
  const reload = useSelector((state: any) => state.userCreate.reload);
  const [form] = Form.useForm();
  const [roles, setRoles] = useState<Permission[]>([]);
  const [isEdit, setEdit] = useState<boolean>(false);
  const [user, setUser] = useState<UserModel>({});
  const idSelecteds: any[] = [];
  useEffect(() => {
    if (isVisible) {
      form.resetFields();
      if (userSelect && userSelect._id) {
        loadUserById();
        setEdit(true);
      } else {
        loadRole();
        setEdit(false);
      }
    }
    return () => {
      dispatch(createActions.reset());
      setRoles([]);
      setUser({});
    };
  }, [isVisible, reload]);

  useEffect(() => {
    loadRole();
  }, [user]);

  function loadRole() {
    userService.getRole().subscribe((results: any) => {
      if (results) {
        if (results && results.length && user.roles) {
          const roles = user.roles;
          roles.forEach((rl: any) => {
            const role: any = linq
              .from(results)
              .selectMany((x: any) => x.actions)
              .firstOrDefault((x: any) => x.key === rl.key);
            if (role) {
              role.isChecked = true;
            }
            results.forEach((ctrl: Permission) => {
              if (ctrl.actions && ctrl.actions.isNotNull()) {
                ctrl.isCheckedAll = ctrl.actions.every((x: any) => x.isChecked);
                const anyChecked = ctrl.actions?.some(x => x.isChecked);
                if (ctrl.isCheckedAll === false && anyChecked) {
                  ctrl.inDeterMinate = true;
                } else {
                  ctrl.inDeterMinate = false;
                }
              }
            });
          });
        }
        setRoles(results);
      }
    });
  }

  async function loadUserById() {
    Loading.show();
    const userResp = await userService.getById<UserModel>(userSelect._id).execute();
    if (userResp) {
      form.setFieldsValue({
        fullName: userResp.fullName,
        email: userResp.email,
      });
      setUser(userResp);
    }
    Loading.hide();
  }

  function handleOk(values: UserModel) {
    getNodeSelected(roles);
    values.roles = idSelecteds;
    values.type = Constants.TYPE_USER_AUTHEN.tnm;
    const userInput = { ...user, ...values };
    // setUser(userInput);
    dispatch(createActions.createOrUpdate(userInput));
  }

  const handleCancel = () => {
    dispatch(createActions.openCreate(false));
  };

  const checkOne = (value: any, item: Permission, controller: Permission) => {
    item.isChecked = value !== undefined ? !value : true;
    let actions = controller.actions;
    if (actions && actions.length) {
      actions = actions.filter(x => x.isHide !== true);
      const oneCheck = actions.every(x => x.isChecked);
      if (oneCheck) {
        controller.isCheckedAll = true;
      } else {
        controller.isCheckedAll = false;
      }
      const anyChecked = actions.some(x => x.isChecked);
      if (controller.isCheckedAll === false && anyChecked) {
        controller.inDeterMinate = true;
      } else {
        controller.inDeterMinate = false;
      }
    }
    setRoles([...roles]);
  };

  const checkAll = (event: CheckboxChangeEvent, value: any, controller: Permission) => {
    event.stopPropagation();
    controller.isCheckedAll = value !== undefined ? !value : true;
    const actions = controller.actions;
    if (actions && actions.length) {
      actions.forEach(element => {
        element.isChecked = value !== undefined ? !value : true;
      });
    }
    setRoles([...roles]);
  };
  const genExtra = (role: any) => (
    <div>
      <Checkbox
        name={role.controller}
        className="mr5 pointer"
        checked={role.isCheckedAll}
        indeterminate={role.inDeterMinate}
        onClick={(e: any) => checkAll(e, role.isCheckedAll, role)}
      />
      {role.name || role.module}
    </div>
  );

  const getNodeSelected = (roles: Permission[]) => {
    roles.forEach(modulex => {
      if (modulex && modulex.actions) {
        modulex.actions.forEach(action => {
          if (action.isChecked && !action.isHide) {
            const item = {
              key: action.key,
              controller: action.controller,
              action: action.method,
              url: action.url,
              type: Constants.TYPE_USER_AUTHEN.tnm,
            };
            idSelecteds.push(item);
          }
        });
      }
    });
  };

  return (
    <div>
      <Modal footer={null} closable={false} visible={isVisible} className="full-window">
        <Form
          onFinish={handleOk}
          form={form}
          layout="vertical"
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 24 }}
          autoComplete="off"
        >
          <ModalHeader>
            <button type="button" className="btn btn-medium m-r-xs" onClick={handleCancel}>
              Close
            </button>
            <button className="btn btn-medium btn-primary">Save</button>
          </ModalHeader>
          <div className="p-t-lg modal-frame">
            <div className="max-w800 margin-inherit limit-modal">
              <h1 className="d-inline-block m-r-md m-b-xl">{props.title}</h1>
              <div className="custom-scroll">
                <h2 className="m-b-lg">User Infomation</h2>
                <Row>
                  <Col md={6}>
                    <Form.Item label="Full Name" name="fullName" rules={[{ required: true }]}>
                      <Input placeholder="Fullname" />
                    </Form.Item>
                  </Col>
                  <Col md={6}>
                    <Form.Item label="Email" name="email" rules={[{ required: true }]}>
                      <Input disabled={isEdit} placeholder="Email" />
                    </Form.Item>
                  </Col>
                </Row>
                {!isEdit && (
                  <Row>
                    <Col md={6}>
                      <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                      >
                        <Input.Password placeholder="Password" />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="confirm"
                        label="Confirm Password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(new Error('The two passwords that you entered do not match!'));
                            },
                          }),
                        ]}
                      >
                        <Input.Password placeholder="Your password confirmation" />
                      </Form.Item>
                    </Col>
                  </Row>
                )}
                <Row>
                  {roles && roles.length ? (
                    <Collapse defaultActiveKey={[roles[0].controller || 0]}>
                      {roles.map(role => (
                        <Panel header={genExtra(role)} key={role.controller!}>
                          <ARow justify="start" style={{ backgroundColor: '#fff' }}>
                            {role.actions &&
                              role.actions.map(action => (
                                <ACol className="mt10 p10 ml20" key={action.key} span={8}>
                                  <Checkbox
                                    name={action.key}
                                    className="mr5 pointer"
                                    checked={action.isChecked}
                                    onChange={() => checkOne(action.isChecked, action, role)}
                                  >
                                    {action.name}
                                  </Checkbox>
                                </ACol>
                              ))}
                          </ARow>
                        </Panel>
                      ))}
                    </Collapse>
                  ) : (
                    <div></div>
                  )}
                </Row>
              </div>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default CreateUpdateUser;
