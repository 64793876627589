import { lastValueFrom, Observable } from 'rxjs';

declare module 'rxjs' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Observable<T> {
    execute<T>(this: Observable<T>): Promise<T>;
  }
}

function execute<T>(this: Observable<T>) {
  return lastValueFrom(this);
}

Observable.prototype.execute = execute;
