import { Constants } from '@config/constant';
import { Loading } from '@helpers/loading';
import WebHelper from '@helpers/webHelper';
import { PaginatedList, SearchCompanyPayment } from '@models/base';
import { CompanyPayment } from '@models/referrer-payment';
import { CompanyPaymentService } from '@services/company-payment.service';
import { Select, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, Subject } from 'rxjs';
import NumberFormat from 'react-number-format';
import CompanyPaymentModal from './modals/company-payment-modal';
const subject = new Subject<any>();
const { Option } = Select;
const searchDataLocal: SearchCompanyPayment = new SearchCompanyPayment(true);

const CompanyPaymentPage = () => {
  const companyPayService: CompanyPaymentService = CompanyPaymentService.instance;
  const paymentStatus = Constants.PAYMENT_STATUS;
  const [list, setList] = useState<CompanyPayment[]>([]);
  const [keyword, setKeyword] = useState<string>('');
  const [searchData, setSearchData] = useState<SearchCompanyPayment>(new SearchCompanyPayment(true));
  const modalChild = useRef<any>(null);
  const columnDefs: ColumnsType<CompanyPayment> = useMemo<ColumnsType<CompanyPayment>>(
    () => [
      {
        key: 'name',
        title: 'Company Name',
        render: value => {
          return (
            <span
              onClick={() => {
                modalChild.current?.showModal(value.companyId);
              }}
              className="table-title pointer"
            >
              {value.company?.name || '-'}
            </span>
          );
        },
      },
      {
        key: 'status',
        title: 'Payment Status',
        dataIndex: 'status',
        render: value => {
          const status = paymentStatus.find(x => x.code === value);
          return <div dangerouslySetInnerHTML={{ __html: status?.html || '-' }} />;
        },
      },
      {
        title: 'Company employee',
        key: 'employee',
        dataIndex: 'employee',
        render: data => {
          return <span>{data || '-'}</span>;
        },
      },
      {
        title: 'Unpaid Employee',
        key: 'unpaid',
        render: data => {
          return <span>{data.employee - data.employeePaid || '-'}</span>;
        },
      },
      {
        title: 'Unpaid Payment',
        key: 'unpaid-payment',
        render: data => {
          return (
            <span>
              <NumberFormat value={data.wallet?.debt} displayType={'text'} thousandSeparator={true} /> VND
            </span>
          );
        },
      },
      {
        title: 'Contact Email',
        key: 'email',
        render: data => {
          return <span>{data.company?.email}</span>;
        },
      },
    ],
    [],
  );
  useEffect(() => {
    loadList();
  }, []);

  useEffect(() => {
    const subcribe = subject.pipe(debounceTime(500)).subscribe({
      next: v => {
        return search();
      },
    });
    return () => {
      WebHelper.unSubscribe(subcribe);
    };
  }, []);

  function loadList(pagination?: any) {
    if (pagination && pagination.current) {
      searchDataLocal.pageNumber = pagination.current;
    }
    Loading.show();
    companyPayService.getAll<PaginatedList<CompanyPayment>>(searchDataLocal).subscribe(result => {
      if (result) {
        result.results.forEach((value, index) => {
          value.key = index;
        });
        setList(result.results);
        searchDataLocal.totalItems = result.totalItem;
        searchDataLocal.pageNumber = searchData.pageNumber;
        setSearchData(searchDataLocal);
        Loading.hide();
      }
    });
  }

  const search = () => {
    searchDataLocal.pageNumber = 1;
    loadList();
  };

  function onSelectedStatus(value: any) {
    searchDataLocal.status = value;
    search();
  }

  const onKeyword = (event: any) => {
    searchDataLocal.keyword = event.target.value;
    setKeyword(event.target.value);
    subject.next(searchData);
  };
  return (
    <div className="custom-scroll p-md">
      <div className="row">
        <div className="col-md-12">
          <div className="form-group width-300 m-b-none m-r-sm d-inline-block">
            <input
              placeholder="Search Name or Email"
              className="form-control have-icon small"
              value={keyword}
              onChange={onKeyword}
            />
            <span className="form-control-icon transform pointer">
              <span className="mat-icon-outline fs-20">search</span>
            </span>
          </div>
          <div className="dropdown m-r-sm d-inline-block">
            <div className="form-group small">
              <Select
                suffixIcon={<span className="mat-icon select-icon">expand_more</span>}
                allowClear={true}
                value={searchData.status}
                onChange={onSelectedStatus}
                placeholder="Payment status"
              >
                {paymentStatus.map(item => (
                  <Option key={item.code} value={item.code}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="col-md-12 m-t-lg custom-table">
          <Table
            columns={columnDefs}
            dataSource={list}
            onChange={loadList}
            pagination={{
              hideOnSinglePage: true,
              total: searchData.totalItems,
              pageSize: searchData.pageSize,
              current: searchData.pageNumber,
              showSizeChanger: false,
            }}
          ></Table>
        </div>
      </div>
      <CompanyPaymentModal ref={modalChild} />
    </div>
  );
};
export default CompanyPaymentPage;
