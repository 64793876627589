import * as CryptoJS from 'crypto-js';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
export class CookieStorage {
  private static secrectKey = 'akes!@#616b6573214023@#$#%24023242325324024402340@#$#%2232473646653@#$#%244465';

  private static encrypt(data: string) {
    if (!data) {
      return '';
    }
    const ciphertext = CryptoJS.AES.encrypt(data, this.secrectKey);
    return ciphertext.toString();
  }

  private static decrypt(data: string) {
    if (!data) {
      return null;
    }
    const bytes = CryptoJS.AES.decrypt(data, this.secrectKey);
    const plaintext = bytes.toString(CryptoJS.enc.Utf8);
    return plaintext;
  }

  static createStore(key: string, data: string) {
    if (data) {
      const hashData = this.encrypt(data);
      cookies.set(key, hashData, {
        path: '/',
        domain: process.env.LOGIN_DOMAIN,
      });
    }
  }

  static createStoreAccess(key: string, data: any) {
    if (data) {
      const hashData = this.encrypt(data);
      cookies.set(key, hashData, {
        path: '/',
        domain: process.env.LOGIN_DOMAIN,
      });
    }
  }

  static getStore(key: string) {
    let hashData = cookies.get(key);
    if (hashData) {
      hashData = hashData.trym('"');
      const plainText = this.decrypt(hashData);
      if (plainText) {
        return plainText;
      }
    }
    return undefined;
  }
  static removeStore(key: string) {
    cookies.remove(key, { path: '/', domain: process.env.LOGIN_DOMAIN });
  }
}
