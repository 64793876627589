import XImage from '@components/Image';
import { AuthContext } from '@contexts/Auth';
import { UserService } from '@services/user.service';
import { Button, Form, Input, Row } from 'antd';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import imgLogo from '../../../assets/images/logo/tnm-logo-square.svg';

const LoginPage: FunctionComponent = () => {
  const userService: UserService = UserService.instance;
  const { login } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm({});
  const onSubmit = async (data: any) => {
    userService.forgotPassword(data.mail).subscribe(res => {
      console.log('ressssssss', res);
    });
  };

  useEffect(() => {});

  return (
    // <Row justify="center" align="middle" style={{ minHeight: '100vh' }}>
    //   <Form size="middle" name="basic" labelCol={{ span: 8 }} wrapperCol={{ span: 24 }} onFinish={login} autoComplete="off">
    //     <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please input your email!' }]}>
    //       <Input />
    //     </Form.Item>
    //     <Form.Item label="Password" name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
    //       <Input.Password />
    //     </Form.Item>
    //     <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
    //       <Button type="primary" htmlType="submit">
    //         Submit
    //       </Button>
    //     </Form.Item>
    //   </Form>
    // </Row>
    <div className="box-out max-w600 margin-auto">
      <div className="row">
        <div className="col-md-5 order-md-2">
          <h1 className="m-b-md">
            <XImage url={imgLogo} alt="avatar" type="LOCAL" />
          </h1>
        </div>
        <div className="col-md-7 text-start order-md-1">
          <h3 className="fs-24 bold"> {!isLogin ? 'Forgot Password' : 'Log In'}</h3>
          {!isLogin ? <p className="fs-14 m-b-md font-lato">Enter your email account</p> : <span></span>}
          <div className="blank-form">
            {isLogin ? (
              <form onSubmit={handleSubmit(login)}>
                <div className="form-group m-b-sm">
                  <label>Email</label>
                  <input className="form-control" type="email" placeholder="Email" {...register('email', { required: true })} />
                  {errors.email && <p className="text-error">Email is required.</p>}
                  {isExpired ? (
                    <span className="text-err m-t-xxxs">
                      This email is not verified.
                      <a>
                        <u>Resend the verification email.</u>
                      </a>
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
                <div className="form-group m-b-xs">
                  <label>Password</label>
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Password"
                    {...register('password', { required: true })}
                  />
                  {errors.password && <p className="text-error">Password is required.</p>}
                </div>
                <div className="m-b-md m-r-sm">
                  <a onClick={() => setIsLogin(false)} className="blank-forgot color-primary regular text-underline font-lato">
                    Forgot your password?
                  </a>
                </div>
                <button className="blank-btn m-b-lg btn btn-primary" type="submit">
                  Log In
                </button>
              </form>
            ) : (
              <form onSubmit={handleSubmit2(onSubmit)}>
                <div className="form-group m-b-lg">
                  <input className="form-control" type="email" placeholder="Email" {...register2('mail', { required: true })} />
                  {errors2.mail && <p className="text-error">Email is required.</p>}
                </div>
                <button type="submit" className="blank-btn btn btn-primary m-b-lg m-r-sm">
                  Request
                </button>
                <button className="blank-btn btn m-b-lg" onClick={() => setIsLogin(true)}>
                  Cancel
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
