export class UrlConstant {
  static readonly ADMIN_FIRSTPAGE = '/admin/dashboard';
  static readonly REFERRAL_FIRSTPAGE = '/user/dashboard';
  static readonly COMPANY_FIRSTPAGE = 'admin-dashboard';
  static readonly TNMUrl = {
    // MAP: url => {
    //   return UrlConstant.TNMUrl.ROOT + '/' + url;
    // },
    ROOT: 'admin',
    DASHBOARD: 'dashboard',
    PROFILE: 'profile',
    JOB: 'job',
    COMPANY: {
      LIST: 'company',
      USER_LIST: 'company-user',
      INVOICE_LIST: 'company-invoice/:companyId',
      INVOICE_DETAIL: 'company-invoice/detail/:invoiceId',
    },
    REFERRER: {
      USER_LIST: 'candidate-user',
    },
    TRANSACTION: {
      // MAP: url => {
      //   return UrlConstant.TNMUrl.ROOT + '/' + UrlConstant.TNMUrl.TRANSACTION.ROOT + '/' + url;
      // },
      ROOT: 'transaction',
      ACCEPT_DEPOSIT: 'accept-recharge',
      ACCEPT_REWARDS: 'accept-reward',
      ACCEPT_WITHDRAW: 'accept-withdraw',
    },
    GROUP_USER: {
      LIST: 'group-user',
      CREATE: 'create-group',
      EDIT: 'edit-group/:id',
    },
    USER: 'user',
    BLOG: {
      LIST: 'blog',
      CATEGORY: 'blog-category',
      CREATE: 'blog/create',
      EDIT: 'blog/edit/:id',
    },
    SETTINGS: {
      // MAP: url => {
      //   return UrlConstant.TNMUrl.ROOT + '/' + UrlConstant.TNMUrl.SETTINGS.ROOT + '/' + url;
      // },
      ROOT: 'setting',
      TERMS: 'terms',
      MAIL_SERVER: 'mail-server',
      BANK_INFO: 'bank-infor',
      TAG: 'tag',
      KEY_SETTING: 'key-setting',
    },
    TERMs_CONDITIONS: 'page-setting',
    FEEDBACK: 'feedback',
    GUIDELINE: 'guidline',
    GUIDELINE_CREATE: 'create-gline',
    USER_CONTACT: 'user-contact',
    PAYMENT: {
      ROOT: 'payment',
      COMPANY: 'company-payment',
    },
  };
  static mapTNM(url: string, sub?: 'setting' | 'transaction') {
    if (!sub) {
      return '/admin/' + url;
    } else if (sub === 'setting') {
      return '/' + UrlConstant.TNMUrl.ROOT + '/' + UrlConstant.TNMUrl.SETTINGS.ROOT + '/' + url;
    } else if (sub === 'transaction') {
      return '/' + UrlConstant.TNMUrl.ROOT + '/' + UrlConstant.TNMUrl.TRANSACTION.ROOT + '/' + url;
    }
    return '';
  }
}
