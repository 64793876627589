import { FunctionComponent } from 'react';

interface BreadCrumbLinkProps {
  breadcrumbs: Array<any>;
}

const BreadCrumbLink: FunctionComponent<BreadCrumbLinkProps> = ({ breadcrumbs }: any) => {
  return (
    <ul className="app-breadcrumb top_1">
      <li className="app-breadcrumb-item not-arr active">
        <div className="app-breadcrumb-link">
          <span className="mat-icon-outline">arrow_back</span>
        </div>
      </li>
      <li className="app-breadcrumb-item not-arr m-r-sm disabled">
        <div className="app-breadcrumb-link">
          <span className="mat-icon-outline">arrow_forward</span>
        </div>
      </li>
      {breadcrumbs.map((item: any) => (
        <li key={item.name} className="app-breadcrumb-item">
          <div className="app-breadcrumb-link">{item.name}</div>
        </li>
      ))}
    </ul>
  );
};

export default BreadCrumbLink;
